import {
  AddressData,
  Appointment,
  AppointmentTypeString,
  CareTeamPod,
  DatabaseMetadata,
  ISOString,
  PatientStatus,
  StateAbbr,
} from '..'
import { HourRanges, WorkingHours } from '../calendar'
import { VerifiableProviderTypeName, VerifiableState } from '../verifiable'

export type EmploymentStatus = 'employee' | 'contractor'
export type EmployeeStatus = 'currentEmployee' | 'formerEmployee'
export type PatientCounts = { [status in PatientStatus]: number }

/**
 * The pared down version of VerifiableProviderInfo that contains
 * only the data necessary for Ophelia clients. Expand as needed.
 */
type ProviderInfo = {
  npi: number | null
  nadea: string
  providerType: VerifiableProviderTypeName | null
  providerId: string
  /**
   * @deprecated - use `deaRegistrations` instead
   */
  deaRegistrationNumbers: string[]
  certificates: {
    type: string
    issueDate: string | null
    expirationDate: string | null
  }[]
  boardCertifications: {
    type: string
    nonVerifiedInfo: {
      initialCertificationDate: string
      expirationDate: string
    }
  }[]
  deaRegistrations: {
    registrationNumber: string
    results: {
      active: boolean | null
      expirationDate: string | null
    }
  }[]
  licenses: {
    isCurrentlyPracticing: boolean
    state: VerifiableState
    licenseNumber: string
    currentVerificationStatus: string
    currentVerifications: {
      issued: string
      expires: string
    }[]
  }[]
}

export type VerifiedClinician = Omit<Employee<'clinician'>, 'providerInfo'> &
  Required<Pick<Employee<'clinician'>, 'providerInfo'>>

export type EmployeeType =
  | 'clinician'
  | 'care-coordinator'
  | 'basic'
  | 'consultation-call-queue'
  | 'admin'

export type EmployeeItem<T extends EmployeeType = EmployeeType> = T extends 'clinician'
  ? Omit<Employee<'clinician'>, 'providerInfo'> & { providerType: VerifiableProviderTypeName }
  : Employee<T>

export type Employee<T extends EmployeeType = EmployeeType> = DatabaseMetadata & EmployeeModel<T>

export type SupportedVisitType = {
  timezone: string
  /**
   * Additional settings to reserve certain appointment times
   */
  settings?: SupportedVisitTypeSettings
}

export type SupportedVisitTypeSettings = { [key in 'high-risk-patient']?: { schedule: HourRanges } }

export const IS_AVAILABLE_FOR_FOLLOW_UP_STANDBY_APPOINTMENT =
  'is_available_for_follow_up_standby_appointment' as const
export const IS_AVAILABLE_FOR_NEXT_PATIENT = 'is_available_for_next_patient' as const

export type StandbyStatus =
  | typeof IS_AVAILABLE_FOR_FOLLOW_UP_STANDBY_APPOINTMENT
  | typeof IS_AVAILABLE_FOR_NEXT_PATIENT

export type StandbyAvailabilityStatus =
  | 'currently_seeing_patient'
  | 'available_for_patient'
  | 'not_available_for_patient'

export const PULLING_FORWARD_NEXT_APPOINTMENT = 'pulling_forward_next_appointment' as const
export const PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY =
  'pulling_forward_another_appointment_scheduled_today' as const
export const PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT =
  'pulling_forward_follow_up_standby_appointment' as const
export const PUSHING_BACK_NEXT_APPOINTMENT = 'pushing_back_next_appointment' as const

export type AvailabilityStatus =
  | typeof PULLING_FORWARD_NEXT_APPOINTMENT
  | typeof PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY
  | typeof PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT
  | typeof PUSHING_BACK_NEXT_APPOINTMENT

export type EmployeeModel<T extends EmployeeType = EmployeeType> = {
  /**
   * Email used for employee registration.
   */
  email: string
  /**
   * Personal name for employee registration.
   */
  name: string
  /**
   * Profile photo URL.
   */
  profilePhotoURL?: string
  /**
   * Legal first name.
   */
  legalFirstName?: string
  /**
   * Legal last name.
   */
  legalLastName?: string
  /**
   * An id of the calendar that represents an Employee's availability in Acuity.
   */
  calendarId: number
  /**
   * Settings of an appointment type string
   */
  supportedVisitTypes?: Record<AppointmentTypeString, SupportedVisitType>
  /**
   * Date of birth.
   */
  dob: string
  /**
   * Is the employee full-time or contractor.
   */
  employmentStatus: EmploymentStatus
  /**
   * Rather than deleting an employee we use employment status to keep a history of Ophelia employees.
   */
  status: EmployeeStatus
  /**
   * Personal email of an employee.
   */
  personalEmail: string
  /**
   * Phone number.
   */
  phone: string
  /**
   * Start date of employment.
   */
  startDate: string
  /**
   * Indicates whether or not an employee is a psych consultant for case review. This needs to be on the generic employee base
   * for now because Dr. Bisaga is an "employee" role type without credentialing data in our system, but is a psych consultant.
   * Once that is no longer true and all our psych consultants are clinicians, this should be moved to Clinician type -3/16/2023
   */
  isPsychConsultant?: boolean
  /**
   * Main address that the employee works from
   */
  workAddress?: AddressData
  /**
   * The employee's Zoom user id, which is used to identify Zoom events that relate to this employee (ie. when they joined / left
   * a meeting, etc.)
   */
  zoomUserId?: string
  /**
   * CRM status used to track available employees for inbound communications
   */
  crmStatus?: {
    /**
     * The last time the employee's online status was updated
     */
    onlineStatusLastUpdatedDatetime: ISOString
    /**
     * The employee's current online status
     */
    onlineStatus: 'online' | 'offline'
    /**
     * The employee's current activity, if they are online
     */
    activityStatus: 'inbound_all' | 'inbound_sms' | 'inbound_call' | 'outbound_only'
  }
  /**
   * List of appointment type Ids associated with this employee's calendarId
   */
  visitTypeIds: number[]
  /**
   * The last time the employee doc was synced with their Acuity appointment type Ids.
   */
  lastAppointmentTypeSync: ISOString
  /**
   * spc - Supervising Clinician (etymology: "Supervising Prescribing Clinician")
   * pc - Prescribing Clinician
   * sncm - Superivising Clinical Care Manager
   * ncm - Clinical Care Manager (etymology: historically "Nurse Care Manager")
   * stn - Supervising Triage Nurse
   * tn - Triage Nurse
   * ncm_tn - Clinical Care Manager and Triage Nurse. This is a special role for employees transitioning from TN to NCM, but still need both roles.
   */
} & (T extends 'clinician'
  ? {
      role: 'spc' | 'pc' | 'sncm' | 'ncm' | 'stn' | 'tn' | 'ncm_tn'
      /**
       * Date of birth.
       */
      dob: string
      /**
       * An id that represents a vendor for prescribing.
       */
      doseSpotId: string
      /**
       * Number of patients that a clincian can prescribe to per month.
       * Legal requirements of how many patients a clinician can prescribe to per month.
       */
      prescribingCap: string
      /**
       * Number of patients that a clinician is prescribing to outside of Ophelia.
       */
      nonOpheliaPatients: string
      /**
       * @deprecated - this is now in `provider` instead.
       * Credentialing system verifiable information.
       */
      providerInfo?: ProviderInfo
      /**
       * Credentialing system verifiable information.
       * Sourced from QGenda.
       */
      provider?: {
        npi: string | null
        qgendaStaffId: string
        providerType: string | null
        stateLicenses: {
          state: StateAbbr | null
          currentlyPracticing: boolean
          status: string | null
          licenseNumber: string
        }[]
        deaLicenses: {
          licenseNumber: string
          state: StateAbbr | null
          status: string | null
        }[]
      }
      /**
       * Count for each patient status
       */
      patientCounts?: PatientCounts
      /**
       * Ability to turn off clinicians for being assigned new patients or being scheduled for followups
       * (New clinicians should have this as "false" unitl they're trained and ready to start seeing patients)
       */
      availableForScheduling?: 'open' | 'closed'
      /**
       * Allow TN to take checkin calls
       */
      availableForCheckins?: 'open' | 'closed'
      /**
       * Licensed states
       * Added here instead of in providerInfo because we need to be able to search by this field in the database.
       */
      licensedStates?: StateAbbr[]
      /**
       * @deprecated - Care team is now indicated by patientSupportSpecialist or patientEngagementSpecialist role
       */
      pod?: CareTeamPod
      /**
       * This clinician's supervising clinician (undefined for scs)
       */
      spc?: string
      /**
       * The working hours for this clinician
       */
      workingHours?: WorkingHours
      /**
       * Clinician's have the ability to pull forward an appointment that is on their calendar in the future
       * or push back their next visit because they need a break. We will store the status of their availability
       * here.
       */
      availability?: {
        status: AvailabilityStatus | null
        appointmentId: Appointment['oid'] | null
        expiresAt: ISOString | null
      } | null
      /**
       * Clinician's have the ability to be on standby for patients that need to be seen sooner.
       * This can be for the next patient or for a follow-up appointment that has been marked as available for standby.
       */
      standbyStatus?: StandbyStatus | null
    }
  : T extends 'consultation-call-queue'
  ? {
      role: 'consultationCallQueue'
      /**
       * The working hours for the consultation call queue
       */
      workingHours?: WorkingHours
    }
  : T extends 'care-coordinator'
  ? {
      role:
        | 'patientEngagementSpecialist'
        | 'patientSupportSpecialist'
        | 'leadPatientEngagementSpecialist'
        | 'leadPatientSupportSpecialist'
      /**
       * The pod this care team member belongs to
       */
      pod?: CareTeamPod
    }
  : T extends 'admin'
  ? {
      role: 'admin'
      // Some admins have dosespot IDs so that they can create patients
      doseSpotId?: string
    }
  : {
      role:
        | 'payorOps'
        | 'engineer'
        | 'product'
        | 'legal'
        | 'employee'
        | 'student'
        | 'resourceNavigator'
        | 'financialCounselor'
        | 'enrollmentCoordinator'
        | 'leadEnrollmentCoordinator'
    })
