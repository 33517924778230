import { useClipboard } from '@mantine/hooks'
import { AiOutlineHistory } from '@react-icons/all-files/ai/AiOutlineHistory'
import { BiCalendar } from '@react-icons/all-files/bi/BiCalendar'
import { BiCalendarCheck } from '@react-icons/all-files/bi/BiCalendarCheck'
import { BiCalendarX } from '@react-icons/all-files/bi/BiCalendarX'
import { GoPencil } from '@react-icons/all-files/go/GoPencil'
import {
  CalendarIcon,
  CheckIcon,
  Divider,
  Flex,
  Group,
  LinkIcon,
  Menu,
  SecondaryButton,
  Skeleton,
  Stack,
  Text,
  TitleTwo,
  Tooltip,
  UserIcon,
  showNotification,
} from '@shared/components'
import {
  Appointment,
  Employee,
  Patient,
  hasAppointmentType,
  hasGroupRole,
  hasRole,
  isAdmin,
  isCoordinator,
  isEngineer,
  isLeadCoordinator,
  isPatientOnboardingOffline,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useMutation } from 'react-query'
import { analytics } from '../../analytics'
import { appointmentsApi } from '../../api'
import AMenuItem from '../../components/atoms/AMenuItem'
import APill from '../../components/atoms/APill'
import IconPlus from '../../components/icons/IconPlus'
import IconVideo from '../../components/icons/IconVideo'
import MDropdown from '../../components/molecules/MDropdown'
import { useAuth } from '../../context/auth'
import { useEmrQuery, useFlags, useInvalidateQuery } from '../../utils/hooks'
import { useAccess } from '../../utils/hooks/use-access'
import { getZoomUrl } from '../../utils/visits'
import { SchedulingFlowVisitTypes } from './PPatientContext'

const MPatientSubheader = ({
  setAddAddendum,
  setVersionHistory,
  setScheduleVisit,
  setCancelVisit,
  setRestoreVisit,
  patientID,
  appointmentLoading,
  visit,
  editMode,
}: {
  setAddAddendum: (bool: boolean) => void
  setVersionHistory: (bool: boolean) => void
  setScheduleVisit: (visitType: SchedulingFlowVisitTypes) => void
  setCancelVisit: (bool: boolean) => void
  setRestoreVisit: (bool: boolean) => void
  patientID: string
  appointmentLoading: boolean
  visit: Appointment | null
  editMode: boolean
  patient: Patient
}) => {
  const { currentUser } = useAuth()
  const hasAccess = useAccess()
  const { zoomUrl, urlToSendToPatient, meetingId, passcode } = getZoomUrl(currentUser, visit)
  const { visitHoldsV2 } = useFlags()
  const { copy, copied } = useClipboard({ timeout: toTime('3 sec').ms() })

  const { data: patient } = useEmrQuery('GET /patient/:patientId', {
    params: { patientId: patientID },
  })
  const isOnboardingOffline = isPatientOnboardingOffline(patient)

  const handleCopyZoomLink = () => {
    if (isOnboardingOffline && meetingId && passcode) {
      copy(`Meeting Id: ${meetingId}, Passcode: ${passcode}`)
      showNotification({
        message: <Text bold>Copied Zoom meeting details</Text>,
        variant: 'success',
        autoClose: true,
      })
    } else if (urlToSendToPatient) {
      copy(urlToSendToPatient)
      showNotification({
        message: <Text bold>Copied Zoom link</Text>,
        variant: 'success',
        autoClose: true,
      })
    } else {
      showNotification({
        message: <Text bold>Zoom details not available</Text>,
        variant: 'error',
        autoClose: true,
      })
    }
  }

  const invalidate = useInvalidateQuery()
  const confirmAppointment = useMutation(appointmentsApi.confirm, {
    onSuccess: () => {
      void invalidate('GET /patient/:patientId/appointments/:appointmentId', {
        params: {
          patientId: patientID,
          appointmentId: String(visit?.id),
        },
      })
    },
  })

  const openZoomRoom = () => {
    if (!zoomUrl) {
      return
    }

    // eslint-disable-next-line no-magic-numbers
    const appointmentIsNotWithinTenMinutes = Math.abs(dayjs().diff(visit?.datetime, 'minutes')) > 10
    if (appointmentIsNotWithinTenMinutes) {
      if (
        !confirm(
          'Are you sure you want to join this Zoom meeting? This appointment is not starting within 10 minutes.',
        )
      ) {
        return
      }
    }

    analytics.track('Zoom started', {
      zoomUrl,
      patientId: patientID,
      visitId: visit?.id,
    })

    window.open(zoomUrl, '_blank')
  }

  const onScheduleVisit = (visitType: SchedulingFlowVisitTypes) => {
    return () => setScheduleVisit(visitType)
  }

  const { data: scheduledByEmployee } = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: visit?.createdById as string } },
    { enabled: Boolean(visit?.createdBy === 'ophelia_employee' && visit.createdById) },
  )

  const { data: employee } = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: visit?.employeeId as string } },
    { enabled: Boolean(visit && visit.employeeId && !visit.calendar) },
  )

  const canEditCancellation =
    isEngineer(currentUser) || isLeadCoordinator(currentUser) || hasRole(currentUser, 'spc')

  const showScheduleButtonAsMenu = visitHoldsV2 && hasAccess.canScheduleVisitHolds

  const canCopyPatientZoomLink =
    urlToSendToPatient &&
    hasAccess.canCopyPatientZoomLink &&
    visit &&
    hasAppointmentType(visit, 'Initial Visit', 'Follow-Up Visit')

  const showConfirmationDropdown = isAdmin(currentUser) || isCoordinator(currentUser)

  return (
    <Stack mt='md' mx='md'>
      <Skeleton visible={appointmentLoading}>
        <Flex gap='md'>
          <Flex sx={{ flex: 1 }}>
            <Group w='100%' position='apart' spacing='sm' align='flex-end'>
              <Group spacing='sm' noWrap>
                <TitleTwo>
                  {capitalize(
                    `${visit?.canceled ? 'Canceled ' : ''}${visit?.type} (${visit?.duration} min)`,
                  )}
                </TitleTwo>
                {showConfirmationDropdown && (
                  <MDropdown
                    label={
                      <APill color={visit?.confirmed ? 'green' : 'gray'}>
                        {visit?.confirmed ? 'Confirmed' : 'Not confirmed'}
                        <GoPencil className='mt-1 ml-1' />
                      </APill>
                    }
                  >
                    <AMenuItem
                      onClick={() => {
                        confirmAppointment.mutate({
                          patientId: patientID,
                          appointmentId: visit?.id ?? 0,
                          confirmed: !visit?.confirmed,
                        })
                      }}
                    >
                      <APill color={visit?.confirmed ? 'gray' : 'green'}>
                        {visit?.confirmed ? 'Not confirmed' : 'Confirmed'}
                      </APill>
                    </AMenuItem>
                  </MDropdown>
                )}
              </Group>
              <Group noWrap>
                <Group noWrap spacing='xs'>
                  <UserIcon color={({ text }) => text[1]} />
                  <Text>{visit?.calendar || employee?.name}</Text>
                </Group>
                <Tooltip
                  disabled={!visit?.createdBy}
                  label={getScheduledByLabel(visit, scheduledByEmployee)}
                >
                  <Group noWrap spacing='xs'>
                    <CalendarIcon color={({ text }) => text[1]} />
                    <Text>
                      {dayjs(visit?.datetime)
                        .tz(visit?.timezone)
                        .format('ddd, MM/DD/YYYY | h:mma z')}
                    </Text>
                  </Group>
                </Tooltip>
              </Group>
            </Group>
          </Flex>
          <Group noWrap spacing='sm'>
            <Tooltip label='Schedule'>
              {showScheduleButtonAsMenu ? (
                <Menu position='bottom-end'>
                  <Menu.Target>
                    <SecondaryButton leftIcon={<BiCalendar className='h-5 w-5' />} />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={onScheduleVisit(['Follow-Up Visit'])}>Follow-up</Menu.Item>
                    <Menu.Item onClick={onScheduleVisit(['Check-In Call'])}>Check-in</Menu.Item>
                    <Menu.Item onClick={onScheduleVisit(['Induction Check-In Call'])}>
                      Induction Check-in
                    </Menu.Item>
                    <Menu.Item onClick={onScheduleVisit(['UDS Visit'])}>UDS</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ) : (
                <SecondaryButton
                  onClick={() => setScheduleVisit(null)}
                  leftIcon={<BiCalendar className='h-5 w-5' />}
                />
              )}
            </Tooltip>
            {canEditCancellation && (
              <Tooltip label='Edit cancelation'>
                <SecondaryButton
                  onClick={() => setRestoreVisit(true)}
                  leftIcon={<BiCalendarCheck className='h-5 w-5' />}
                />
              </Tooltip>
            )}
            {!visit?.canceled && (
              <Tooltip label='Cancel'>
                <SecondaryButton
                  onClick={() => setCancelVisit(true)}
                  leftIcon={<BiCalendarX className='h-5 w-5' />}
                />
              </Tooltip>
            )}
            <Tooltip label={zoomUrl ? 'Open Zoom' : 'No Zoom room'}>
              <SecondaryButton
                onClick={openZoomRoom}
                leftIcon={<IconVideo color='text-daybreak-text3' />}
              />
            </Tooltip>
            {canCopyPatientZoomLink &&
              (copied ? (
                <SecondaryButton leftIcon={<CheckIcon />} />
              ) : (
                <Tooltip
                  label={
                    isOnboardingOffline
                      ? "Copy patient's Zoom meeting details"
                      : "Copy patient's Zoom link"
                  }
                >
                  <SecondaryButton leftIcon={<LinkIcon />} onClick={handleCopyZoomLink} />
                </Tooltip>
              ))}
            {visit && hasGroupRole(currentUser, 'engineer') && (
              <Tooltip label='Version history'>
                <SecondaryButton
                  onClick={() => setVersionHistory(true)}
                  leftIcon={<AiOutlineHistory fontSize='20px' />}
                />
              </Tooltip>
            )}
            {!editMode &&
              visit &&
              !hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call') && (
                <Tooltip label='Add addendum'>
                  <SecondaryButton onClick={() => setAddAddendum(true)} leftIcon={<IconPlus />} />
                </Tooltip>
              )}
          </Group>
        </Flex>
      </Skeleton>
      <Divider />
    </Stack>
  )
}

const getScheduledByLabel = (visit: Appointment | null, employee: Employee | undefined) => {
  if (visit?.createdBy === 'system') {
    return `Scheduled automatically`
  }

  if (visit?.createdBy === 'ophelia_employee') {
    return `Scheduled by ${employee?.name ?? 'employee'}`
  }

  if (visit?.createdBy === 'patient') {
    return `Scheduled by patient`
  }
}

export default MPatientSubheader
