/* eslint-disable no-magic-numbers */

import {
  EM_DASH,
  FlagIcon,
  Group,
  LockIcon,
  MantineTheme,
  Stack,
  Text,
  useMantineTheme,
} from '@shared/components'
import { AppointmentEventForRender, AppointmentTypeString, getVisitTypeName } from '@shared/types'
import { dayjs } from '@shared/utils'
import { CalendarEvent } from './CalendarEvent'

export type AppointmentEventProps = {
  block: AppointmentEventForRender
  hasAvailableSlots: boolean
}

const visitType2Color = (theme: MantineTheme, type: AppointmentTypeString, isInPast = false) => {
  const primaryVisitTypes: AppointmentTypeString[] = [
    'Free Consultation Call',
    'Returning welcome call',
    'Initial Visit',
  ]
  if (primaryVisitTypes.includes(type)) {
    return isInPast ? theme.other.colors.success[1] : theme.other.colors.success[0]
  }

  const secondaryVisitTypes: AppointmentTypeString[] = [
    'Follow-Up Visit',
    'Enrollment Support Call',
  ]
  if (secondaryVisitTypes.includes(type)) {
    return isInPast ? theme.other.colors.warning[1] : theme.other.colors.warning[0]
  }

  if (type === 'Check-In Call') {
    return isInPast ? theme.other.colors.actions[0] : theme.other.colors.actions[1]
  }

  if (type === 'Induction Check-In Call') {
    return isInPast ? theme.other.colors.background[5] : theme.other.colors.background[6]
  }

  return isInPast ? theme.other.colors.background[3] : theme.other.colors.background[4]
}

const visitType2FontColor = (
  theme: MantineTheme,
  type: AppointmentTypeString,
  isInPast = false,
) => {
  if (type === 'Check-In Call' && !isInPast) {
    return theme.other.colors.text[3]
  }

  if (type === 'Induction Check-In Call') {
    return theme.other.colors.text[3]
  }

  return theme.other.colors.text[0]
}

export const AppointmentEvent = ({ block, hasAvailableSlots = false }: AppointmentEventProps) => {
  const theme = useMantineTheme()

  const { event } = block
  const isNoShow = block.event.noShow
  const isInPast = dayjs(event.datetime).isBefore(dayjs())
  const muted = isInPast || hasAvailableSlots

  const color = visitType2Color(theme, event.type, muted)
  const fontColor = visitType2FontColor(theme, event.type, muted)

  let borderColor
  if (isNoShow) {
    borderColor = visitType2Color(theme, event.type)
  } else if (block.indent > 0) {
    borderColor = theme.other.colors.text[3]
  }

  return (
    <CalendarEvent
      color={isNoShow ? theme.other.colors.background[0] : color}
      borderColor={borderColor}
      duration={block.duration}
      tooltip={
        <>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {getVisitTypeName(event.type)}
          </Text>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {dayjs(event.datetime).format('h:mma')} {EM_DASH}{' '}
            {dayjs(event.datetime).add(Number(event.duration), 'minutes').format('h:mma')}
          </Text>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {`${event.lastName}, ${event.firstName}${
              event.metadata?.highRiskPatient ? ' | High risk' : ''
            }`}
          </Text>
        </>
      }
      link={`/patients/${event.userId}/notes/${event.id}`}
      shadow={block.indent > 0}
    >
      <Stack spacing={0} px='xs'>
        <Group position='apart' noWrap align='flex-start'>
          <Text
            strikethrough={isNoShow}
            size={block.duration >= 15 ? 'md' : 'xs'}
            bold
            color={fontColor}
          >
            {`${event.lastName}, ${event.firstName}`}
          </Text>
          <Group spacing='sm' mt='xs' noWrap>
            {event?.noteDetails.lockedAt && <LockIcon color={fontColor} />}
            {event.metadata?.highRiskPatient && <FlagIcon color={fontColor} />}
          </Group>
        </Group>
        {parseInt(event.duration) >= 30 && (
          <Text strikethrough={isNoShow} color={fontColor} size='xs'>
            {getVisitTypeName(event.type)}
          </Text>
        )}
      </Stack>
    </CalendarEvent>
  )
}
