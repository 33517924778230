import { useScrollIntoView } from '@mantine/hooks'
import { Stack, Text, useMantineTheme } from '@shared/components'
import { Patient } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import { useEffect, useRef, useState } from 'react'
import { useLunaQuery } from '../../../../../utils/hooks'
import { CommunicationsDatePill } from '../CommunicationsDatePill'
import { CommunicationsLog } from '../CommunicationsLog'
import { CommunicationsCall } from './CommunicationsCall'

type CallLogProps = {
  patient: Patient
}

export const CallLog = ({ patient }: CallLogProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const [hasInitialData, setHasInitialData] = useState(false)
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>()

  // By default, fetch calls from the past month
  const startDatetimeRef = useRef(dayjs().subtract(1, 'month').startOf('day').toISOString())

  const communicationsQuery = useLunaQuery(
    'GET /communications/calls',
    {
      query: {
        patientId: patient?.oid,
        startDatetime: startDatetimeRef.current,
      },
    },
    {
      enabled: Boolean(patient.oid),
      refetchInterval: toTime('10 sec').ms(),
      refetchIntervalInBackground: false,
    },
  )

  const { data, isLoading } = communicationsQuery

  useEffect(() => {
    if (!hasInitialData && data) {
      scrollIntoView()
      setHasInitialData(true)
    }
  }, [hasInitialData, data, scrollIntoView])

  const calls = data?.data?.calls

  let lastDate: string | null | undefined = undefined

  return (
    <CommunicationsLog
      patient={patient}
      isLoading={isLoading}
      scrollableRef={scrollableRef}
      targetRef={targetRef}
    >
      <>
        {calls?.length && calls?.length > 0 ? (
          calls.map(call => {
            // Show date pill for all unique dates
            const isNewDate = !lastDate || !dayjs(lastDate).isSame(dayjs(call.startTime), 'day')
            lastDate = call.startTime

            return (
              <Stack spacing='md' key={call.externalId}>
                {isNewDate && <CommunicationsDatePill datetime={call.startTime} />}
                <CommunicationsCall
                  externalId={call.externalId}
                  direction={call.direction}
                  datetime={call.startTime}
                  duration={call.duration}
                  status={call.status}
                />
              </Stack>
            )
          })
        ) : (
          <Text color={colors.text[1]}>No calls found from the past month.</Text>
        )}
      </>
    </CommunicationsLog>
  )
}
