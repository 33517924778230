import { BannerPortal, Tabs } from '@shared/components'
import { EmrLunaApi, EncounterStatuses } from '@shared/types'
import { useState } from 'react'
import AAlertBanner from '../../../components/atoms/AAlertBanner'
import { Page } from '../../../components/templates/TDefault'
import { useLunaQuery, useTypedHistory, useTypedParams } from '../../../utils/hooks'
import ListAndCardLayout from '../../care_team/irq/ListAndCardLayout'
import { LoadingEncountersList } from '../../core/loading/LoadingComponents'
import BillingHeaderContent from '../BillingHeaderContent'
import { EncounterCard } from './EncounterCard'
import EncounterList from './EncounterList'

export const reviewableStatuses = [EncounterStatuses.Unsubmitted, EncounterStatuses.Flagged]

type Encounter = EmrLunaApi['GET /encounters']['res']['data']['encounters'][0]
export type PayerIdentificationType = 'payerId' | 'payerEnrollmentId'
export type PayerIdentification = { id: string; type: PayerIdentificationType }

const BillingEncounters = () => {
  const [cursors, setCursors] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const currentCursor = cursors[currentPage - 1]
  const [globalError, setGlobalError] = useState('')
  const [encounters, setEncounters] = useState<Encounter[]>([])
  const [index, setIndex] = useState(0)
  const per = 20
  const { replace } = useTypedHistory()
  const { pathParams } = useTypedParams('/billing/encounters/:status/:encounterId')
  const status = pathParams.status as EncounterStatuses
  const { encounterId } = pathParams
  const [activeTab, setActiveTab] = useState<EncounterStatuses | null>(
    (pathParams.status as EncounterStatuses) || EncounterStatuses.Unsubmitted,
  )

  /*
   * Payers can either be identified by a single payerId or
   * payerEnrollmentId (which we can use to pull a list of payer ids for a given payerEnrollment)
   */
  const [payerIdentification, setPayerIdentification] = useState<PayerIdentification | undefined>()

  const encountersQuery = useLunaQuery(
    'GET /encounters',
    {
      query: {
        status,
        order: reviewableStatuses.includes(status) ? 'asc' : 'desc',
        limit: per.toString(),
        startAfter: currentCursor,
        ...(payerIdentification ? { [payerIdentification.type]: payerIdentification.id } : {}),
      },
    },
    {
      onSuccess: ({ data }) => {
        setEncounters(data.encounters)
        if (data?.encounters?.length === 0) {
          replace(`/billing/encounters/${status}/`)
          return
        }
        let encounterUrlParam = encounterId
        const encounter = data?.encounters?.find(encounter => encounter.oid === encounterUrlParam)
        if (!encounter && data?.encounters?.length > index) {
          encounterUrlParam = data.encounters[index]?.oid
        } else if (!encounter) {
          encounterUrlParam = data?.encounters[index - 1]?.oid
        }
        replace(`/billing/encounters/${status}/${encounterUrlParam}`)
        const body = document.querySelector('body')
        if (body) {
          body.scrollTo(0, 0)
        }
      },
    },
  )

  const encountersCountQuery = useLunaQuery('GET /encounters/count', {
    query: {
      status,
      ...(payerIdentification ? { [payerIdentification.type]: payerIdentification.id } : {}),
    },
  })

  const onClickTab = (value: EncounterStatuses) => {
    setActiveTab(value)
    setIndex(0)
    setCurrentPage(0)
    setCursors([])
    setPayerIdentification(undefined)
    replace(`/billing/encounters/${value}`)
  }

  return (
    <Page
      title='Billing'
      headerContent={<BillingHeaderContent selectedPage='Encounters' />}
      pushHeaderContent={false}
    >
      <BannerPortal />
      <Tabs
        pt='md'
        pb='lg'
        value={activeTab}
        onTabChange={(value: EncounterStatuses) => onClickTab(value)}
      >
        <Tabs.List pl='lg'>
          <Tabs.Tab value={EncounterStatuses.Unsubmitted}>Unsubmitted</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.Flagged}>Flagged</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.Submitted}>Submitted</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.NotBillable}>Not billable</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.NotOnAllowList}>Not insurance pay</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.AutoSubmitting}>Auto submitting</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <div className='grid grid-cols-3 mx-auto px-4 sm:px-6 lg:px-8 w-full'>
        <ListAndCardLayout
          list={
            encountersQuery.isFetching ? (
              <LoadingEncountersList length={8} />
            ) : (
              <EncounterList
                encounters={encounters}
                setEncounters={setEncounters}
                setIndex={setIndex}
                payerIdentification={payerIdentification}
                setPayerIdentification={({ id, type }: PayerIdentification) =>
                  setPayerIdentification({ id, type })
                }
                count={Number(encountersCountQuery.data?.data?.count)}
                encountersPerPage={per}
                setCursors={setCursors}
                setPage={setCurrentPage}
                page={currentPage}
              />
            )
          }
          emptyListMessage={
            !encountersQuery.isFetching &&
            Number(encountersCountQuery.data?.data?.count) === 0 &&
            !payerIdentification
              ? 'There are currently no encounters.'
              : undefined
          }
          card={<EncounterCard />}
        />
      </div>

      {globalError && (
        <AAlertBanner onClose={() => setGlobalError('')} message={globalError} type='error' />
      )}
    </Page>
  )
}

export default BillingEncounters
