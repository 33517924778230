import { Avatar, Box, Group, Pill, Stack, TertiaryButton, Text } from '@shared/components'
import { AppointmentTypes, hasGroupRole, hasRole, isClinician } from '@shared/types'
import { isTruthy } from '@shared/utils'
import uniqBy from 'lodash/uniqBy'
import { forwardRef, useMemo } from 'react'
import { useQuery } from 'react-query'
import { appointmentsApi } from '../../api'
import { useEmployees, useEmrQuery, useLunaQuery } from '../../utils/hooks'
import { useInitialBhcm } from '../patient/use-initial-bhcm'

export const TOGGLE_CLINICIANS_TEXT = 'toggleClinicians'

export const getVisitType = (appointmentTypeId: string, appointmentTypes?: AppointmentTypes) => {
  if (appointmentTypes) {
    if (appointmentTypes.followUpTypes.some(item => item.id === Number(appointmentTypeId))) {
      return 'Follow-Up Visit'
    }

    if (appointmentTypes.bhcmVisitTypes.some(item => item.id === Number(appointmentTypeId))) {
      return 'Wellness Visit'
    }

    if (appointmentTypes.UDS_VISIT_ID === Number(appointmentTypeId)) {
      return 'UDS Visit'
    }

    if (
      [
        appointmentTypes.CONSULTATION_VISIT_ID,
        appointmentTypes.CONSULTATION_QUEUE_CALENDAR_ID,
        appointmentTypes.ENROLLMENT_SUPPORT_CALL_VISIT_ID,
        appointmentTypes.REENROLLMENT_VISIT_ID,
      ].includes(Number(appointmentTypeId))
    ) {
      return 'Consultation/Enrollment Visit'
    }

    if (appointmentTypes.CHECK_IN_CALL_VISIT_ID === Number(appointmentTypeId)) {
      return 'Check-In Call'
    }

    if (appointmentTypes.INDUCTION_CHECK_IN_CALL_VISIT_ID === Number(appointmentTypeId)) {
      return 'Induction Check-In Call'
    }

    if (Object.values(appointmentTypes.initialVisitTypes).includes(Number(appointmentTypeId))) {
      return 'Initial Visit'
    }
  }

  return 'Other'
}

export const useAvailableEmployees = ({
  patientId,
  appointmentTypeId,
}: {
  patientId: string
  appointmentTypeId: string
}) => {
  const patientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId,
      },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const appointmentTypesQuery = useQuery(['appointmentsApi.types'], appointmentsApi.types)

  // This list returns all in state CCMs
  const inStateCCMsQuery = useEmrQuery('GET /patients/:patientId/ccmMatching', {
    params: { patientId },
    /*
     * Note: we added `shouldFilterByInNetworkStatus` because we use 1 function on the backend to
     * get a list of eligible CCMs for many contexts (scheduling drawer, assigning CCMs to patients, etc.).
     * In some contexts, we want to filter by in-network status, and in others (like this one), we don't.
     */
    query: { shouldFilterByInNetworkStatus: 'no' },
  })

  const inStatePcQuery = useLunaQuery('GET /scheduling/:patientId/clinicians', {
    params: {
      patientId,
    },
  })

  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const { isEligibleForCocm } = useInitialBhcm()

  const appointmentTypes = appointmentTypesQuery.data
  const assignedPc = patientQuery.data?.primaryClinician
  const assignedCcm = patientQuery.data?.nurseCareManager

  const state: string = patientQuery.data?.homeData?.state ?? ''
  const stateAbbreviation = appointmentTypes?.stateNameToAbbr[state] ?? ''
  const intakeVisitId = appointmentTypes?.initialVisitTypes[stateAbbreviation]

  const visitType = getVisitType(appointmentTypeId, appointmentTypes)

  const { shouldScheduleInitialBhcmVisit, initialBhcmVisitTypeId } = useInitialBhcm()

  const isLoading =
    appointmentTypesQuery.isLoading ||
    patientQuery.isLoading ||
    inStateCCMsQuery.isLoading ||
    inStatePcQuery.isLoading ||
    employeesQuery.isLoading

  const initialBhcmVisitTypeString = initialBhcmVisitTypeId
    ? getVisitType(initialBhcmVisitTypeId, appointmentTypes)
    : undefined
  const { recommendedOptions, otherOptions } = useMemo((): {
    recommendedOptions: ClinicianOption[]
    otherOptions: ClinicianOption[]
  } => {
    // All clinicians with acuity calendars are possible scheduling options, depending on the type of visits
    const inStateCCMList = inStateCCMsQuery.data?.filter(({ employee }) => employee.calendarId)

    const clinicians = isClinician(employeesQuery.data ?? []).filter(
      clinician => clinician.calendarId,
    )

    const getAssignedPcListItem = () => {
      if (assignedPc) {
        const assignedPcFullInfo = employeesQuery.data?.find(
          employee => employee.oid === assignedPc.id,
        )
        return {
          value: assignedPc.id,
          description: `Patient's PC`,
          label: assignedPc.name,
          photo: assignedPcFullInfo?.profilePhotoURL,
        }
      }
    }

    const getInStatePcListItem = () => {
      return (inStatePcQuery?.data?.data?.clinicians ?? [])
        .filter(employee => ['pc', 'spc'].includes(employee.role))
        .map(employee => ({
          value: employee.oid,
          description: 'Primary clinician',
          label: employee.name,
          photo: employee?.profilePhotoURL,
        }))
    }

    const getAssignedCcmListItem = ({ includeNcmTns }: { includeNcmTns: boolean }) => {
      if (assignedCcm) {
        const assignedCcmFullInfo = employeesQuery.data?.find(
          employee => employee.oid === assignedCcm.id,
        )

        if (!includeNcmTns && hasRole(assignedCcmFullInfo, 'ncm_tn')) {
          return
        }
        return {
          value: assignedCcm.id,
          description: `Patient's CCM`,
          label: assignedCcm.name,
          photo: assignedCcmFullInfo?.profilePhotoURL,
        }
      }
    }

    const getInStateCcmListItems = ({
      onlyAvailableForScheduling,
      includeNcmTns,
    }: {
      onlyAvailableForScheduling: boolean
      includeNcmTns: boolean
    }) => {
      if (inStateCCMList?.length) {
        // include all CCMs for check-in calls and UDS visits, whether or not they're 'availableForScheduling'. For followups, we only show avaialble CCMs
        const eligibleList = inStateCCMList
          .filter(
            ({ availableForScheduling }) =>
              !onlyAvailableForScheduling || availableForScheduling !== 'closed',
          )
          .filter(employee => {
            if (includeNcmTns) {
              return true
            }
            return !hasRole(employee.employee, 'ncm_tn')
          })

        return eligibleList.map(({ employee: { oid, name, profilePhotoURL } }) => ({
          value: oid,
          description: 'CCM',
          label: name,
          inState: true,
          photo: profilePhotoURL,
        }))
      }
      return []
    }

    const getTnListItems = ({ includeNcmTns }: { includeNcmTns: boolean }) => {
      return clinicians
        .filter(employee => {
          if (includeNcmTns) {
            return hasRole(employee, 'stn', 'tn', 'ncm_tn')
          }
          return hasRole(employee, 'stn', 'tn')
        })
        .map(employee => ({
          value: employee.oid,
          description: 'TN',
          label: employee.name,
          photo: employee.profilePhotoURL,
        }))
    }

    const getEnrollmentCoordinatorListItems = () => {
      return (employeesQuery.data ?? [])
        .filter(employee => hasGroupRole(employee, 'enrollmentCoordinator'))
        .map(employee => ({
          value: employee.oid,
          description: 'Enrollment coordinator',
          label: employee.name,
          photo: undefined,
        }))
    }

    const getOutOfStateCcmListItems = ({ includeNcmTns }: { includeNcmTns: boolean }) => {
      const roles = includeNcmTns
        ? (['sncm', 'ncm', 'ncm_tn'] as const)
        : (['sncm', 'ncm'] as const)
      return clinicians
        .filter(
          employee =>
            hasRole(employee, ...roles) &&
            !inStateCCMList?.find(ccm => ccm.employee.oid === employee.oid),
        )
        .map(({ oid, name, profilePhotoURL }) => ({
          value: oid,
          description: 'CCM',
          label: name,
          // we know these clinicians will all be out of state, otherwise they would've been included in the inStateCCMList
          inState: false,
          photo: profilePhotoURL,
        }))
    }

    /*
     * This function is used to filter out duplicate values between the recommended and other options.
     * If a clinician is in both lists, we only show them in the recommended list.
     */
    const filterOutDuplicates = ({
      recommendedOptions,
      otherOptions,
    }: {
      recommendedOptions: ClinicianOption[]
      otherOptions: ClinicianOption[]
    }) => {
      const uniqueRecommendedOptions = uniqBy(recommendedOptions, item => item.value)

      const uniqueOtherOptions = uniqBy(
        otherOptions.filter(otherOption =>
          recommendedOptions.every(recOption => recOption.value !== otherOption.value),
        ),
        item => item.value,
      )

      return { uniqueRecommendedOptions, uniqueOtherOptions }
    }

    let recommendedOptions: ClinicianOption[] = []
    let otherOptions: ClinicianOption[]
    switch (visitType) {
      case 'Consultation/Enrollment Visit': {
        const availableList = getEnrollmentCoordinatorListItems()
        // No recommended list for consultation/enrollment visits
        otherOptions = availableList
        break
      }
      case 'Wellness Visit': {
        const availableList = [getAssignedCcmListItem({ includeNcmTns: true })].filter(isTruthy)
        // No recommended list for wellness visits
        otherOptions = availableList
        break
      }
      case 'Follow-Up Visit': {
        const clinicianList: (ClinicianOption | undefined)[] = []

        // For follow-up visits, we will only display a CCM as an option if the patient has a CCM assigned to them
        if (assignedCcm) {
          clinicianList.push(getAssignedCcmListItem({ includeNcmTns: true }))
        }

        // Filter out duplicate values
        const availableList = uniqBy(
          [...clinicianList, getAssignedPcListItem()].filter(isTruthy),
          item => item.value,
        )

        // If this is an initial bhcm visit, we move the assigned CCM to the reccommended list
        if (shouldScheduleInitialBhcmVisit && initialBhcmVisitTypeString === visitType) {
          const ccmIndex = availableList.findIndex(item => item.value === assignedCcm?.id)
          if (ccmIndex > -1) {
            recommendedOptions = availableList.splice(ccmIndex, 1)
          }
        }
        otherOptions = availableList
        break
      }

      // Employees with the ncm_tn role are filtered out of the list for UDS visits and check-in calls if the patient is not in cocm
      case 'UDS Visit': {
        recommendedOptions = getTnListItems({ includeNcmTns: isEligibleForCocm })
        otherOptions = [
          getAssignedCcmListItem({ includeNcmTns: isEligibleForCocm }),
          getAssignedPcListItem(),
          ...getInStateCcmListItems({
            onlyAvailableForScheduling: false,
            includeNcmTns: isEligibleForCocm,
          }),
          ...getOutOfStateCcmListItems({ includeNcmTns: isEligibleForCocm }),
        ].filter(isTruthy)

        break
      }

      case 'Check-In Call':
      case 'Induction Check-In Call': {
        // If the current visit is an initial bhcm visit, we move the assigned CCM to the reccommended list
        if (shouldScheduleInitialBhcmVisit && initialBhcmVisitTypeString === visitType) {
          recommendedOptions = [
            getAssignedCcmListItem({ includeNcmTns: isEligibleForCocm }),
          ].filter(isTruthy)

          otherOptions = [
            getAssignedPcListItem(),
            ...getInStateCcmListItems({
              onlyAvailableForScheduling: false,
              includeNcmTns: isEligibleForCocm,
            }),
            ...getTnListItems({ includeNcmTns: isEligibleForCocm }),
            ...getOutOfStateCcmListItems({ includeNcmTns: isEligibleForCocm }),
          ].filter(isTruthy)
        } else {
          // Otherwise, we reccommend the TNs
          recommendedOptions = [...getTnListItems({ includeNcmTns: isEligibleForCocm })].filter(
            isTruthy,
          )

          otherOptions = [
            getAssignedCcmListItem({ includeNcmTns: isEligibleForCocm }),
            getAssignedPcListItem(),
            ...getInStateCcmListItems({
              onlyAvailableForScheduling: false,
              includeNcmTns: isEligibleForCocm,
            }),
            ...getTnListItems({ includeNcmTns: isEligibleForCocm }),
            ...getOutOfStateCcmListItems({ includeNcmTns: isEligibleForCocm }),
          ].filter(isTruthy)
        }

        break
      }

      case 'Initial Visit': {
        otherOptions = getInStatePcListItem()

        break
      }

      default: {
        // This should never happen unless a new visit type is added.
        otherOptions = []
        break
      }
    }

    // Filter out duplicate values
    const { uniqueRecommendedOptions, uniqueOtherOptions } = filterOutDuplicates({
      recommendedOptions,
      otherOptions,
    })

    return { recommendedOptions: uniqueRecommendedOptions, otherOptions: uniqueOtherOptions }
  }, [
    initialBhcmVisitTypeString,
    shouldScheduleInitialBhcmVisit,
    inStateCCMsQuery.data,
    employeesQuery.data,
    inStatePcQuery.data,
    visitType,
    assignedCcm,
    assignedPc,
    isEligibleForCocm,
  ])

  return {
    recommendedOptions,
    otherOptions,
    isLoading,
    employees: employeesQuery.data,
    isInitialVisit: Number(appointmentTypeId) === intakeVisitId,
    patient: patientQuery.data,
    appointmentTypes,
  }
}

export type ClinicianOption = {
  value: string
  description: string
  label: string
  photo: string | undefined
  inState?: boolean
}

export type ButtonOption = {
  buttonLabel: string
  buttonIcon: React.ReactNode
  onMouseDown: () => void
  value: typeof TOGGLE_CLINICIANS_TEXT
}

export type SelectClinicianItem<T extends 'button' | 'selectItem' = 'button' | 'selectItem'> =
  T extends 'selectItem'
    ? ClinicianOption & { type: T }
    : T extends 'button'
    ? ButtonOption & { type: T }
    : never

export const SelectClinicianItem = forwardRef<HTMLDivElement, SelectClinicianItem>((props, ref) => {
  if (props.type === 'button') {
    return (
      <Box
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[2],
        })}
        {...props}
        ref={ref}
        // Prevent the dropdown from closing when clicking on this `toggleClinicians` item
        onMouseDown={e => {
          e.preventDefault()
          props.onMouseDown()
        }}
      >
        <Stack align='center' spacing='sm'>
          <Text>Need other options?</Text>
          <TertiaryButton leftIcon={props.buttonIcon}>{props.buttonLabel}</TertiaryButton>
        </Stack>
      </Box>
    )
  }

  const { description, label, inState, photo, ...others } = props
  return (
    <div {...others} ref={ref}>
      <Group noWrap spacing='sm' sx={({ other: { colors } }) => ({ color: colors.text[0] })}>
        <Avatar src={photo} />
        <Group align='flex-start' position='apart' sx={{ width: '100%' }}>
          <Stack spacing={0}>
            <Text bold>{label}</Text>
            <Text size='xs'>{description}</Text>
          </Stack>
          <Group spacing='sm' position='right'>
            {inState && (
              <Pill variant='filled' status='success'>
                In state
              </Pill>
            )}
            {inState === false && (
              <Pill variant='filled' status='warning'>
                Out of state
              </Pill>
            )}
          </Group>
        </Group>
      </Group>
    </div>
  )
})

SelectClinicianItem.displayName = 'SelectClinicianItem'
