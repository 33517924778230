import {
  Alert,
  AlertIcon,
  Checkbox,
  Grid,
  Select,
  Textarea,
  TextInput,
  TooltipLabel,
} from '@shared/components'
import {
  DispenseUnitType,
  DOSESPOT_DIRECTIONS_CHAR_LIMIT,
  DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT,
  DoseSpotMedication,
  DoseSpotMedicationSearchResult,
  DoseSpotPharmacy,
  FormularyMedicationNDC,
} from '@shared/types'
import { isControlledSubstance } from '@shared/utils'
import { useQuery } from 'react-query'
import { prescriptionsApi } from '../../../api'
import { searchForExactMedication } from '../../../utils/prescriptionUtils'
import { usePatient } from '../PPatientContext'
import { useMedicationFormContext } from './formHelpers'
import { MedicationSearchBar } from './MedicationSearchBar'
import PharmacySection from './PharmacySection'

export type EditQueuedPrescriptionDrawerProps = {
  pharmacy?: DoseSpotPharmacy
  pharmacyUpdateOnClick: () => void
}

const EditQueuedPrescriptionDrawer = ({
  pharmacy,
  pharmacyUpdateOnClick,
}: EditQueuedPrescriptionDrawerProps) => {
  const { patientQuery } = usePatient()

  const form = useMedicationFormContext()

  useQuery(
    ['searchForExactMedication', form.values.medication?.Name || ''],
    () => {
      return searchForExactMedication({
        medicationName: form.values.medication?.Name || '',
        // We set the dispensableDrugId value directly on the form
        dispensableDrugId: form.values.dispensableDrugId,
      })
    },
    {
      onSuccess: (searchResult: DoseSpotMedicationSearchResult) => {
        form.setFieldValue('medication', searchResult)

        /*
         * When we edit a prescription, we need to set the dispensableDrugId on the form
         * so that we can use it to look up the medication in DoseSpot
         */
        form.setFieldValue('dispensableDrugId', searchResult.DispensableDrugId)
      },
      enabled: Boolean(form.values.medication?.Name),
      keepPreviousData: true,
    },
  )

  const { full_medication } = form.values

  const { medication } = form.values
  const { medication_strength } = form.values
  const medication_ndc = medication?.Strength.find(
    strength => strength.Strength === medication_strength,
  )?.NDC as FormularyMedicationNDC | undefined
  const priorAuthorizationRequired = medication_ndc
    ? patientQuery?.data?.prescriptionBenefits?.prescriptionCoverageData?.medications[
        medication_ndc
      ]?.priorAuthorizationRequired
    : false

  useQuery(
    ['prescriptionsApi.getMedication', medication],
    () => {
      return prescriptionsApi.getMedication({
        medication_strength,
        medication: medication?.Name || '',
        dispensableDrugId: form.values.dispensableDrugId,
      })
    },
    {
      onSuccess: (fullMedication: DoseSpotMedication) => {
        if (isControlledSubstance(fullMedication.Schedule)) {
          form.setFieldValue('medication_refills', '0')
        }
        form.setFieldValue('full_medication', fullMedication)
      },
      enabled: Boolean(medication) && Boolean(medication_strength),
    },
  )

  return (
    <Grid>
      <Grid.Col span={12}>
        <MedicationSearchBar
          {...form.getInputProps('medication')}
          value={medication}
          onChange={item => {
            form.setFieldValue('full_medication', undefined)
            form.setFieldValue('medication', item)
            form.setFieldValue('medication_strength', '')
            if (!item) {
              form.setFieldValue('medication_refills', '')
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          {...form.getInputProps('medication_strength')}
          label='Strength'
          data={medication ? medication.Strength.map(strength => strength.Strength) : []}
          disabled={!medication}
          placeholder='Select one...'
          defaultValue={medication_strength}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          {...form.getInputProps('medication_refills')}
          label={
            <TooltipLabel
              label={
                isControlledSubstance(full_medication?.Schedule)
                  ? `Schedule ${full_medication?.Schedule} drugs may not enter a refill value.`
                  : undefined
              }
            >
              Refills
            </TooltipLabel>
          }
          disabled={!full_medication || isControlledSubstance(full_medication.Schedule)}
          placeholder={isControlledSubstance(full_medication?.Schedule) ? '0' : '0, 1, or 2'}
        />
      </Grid.Col>

      {priorAuthorizationRequired && (
        <Grid.Col span={12}>
          <Alert
            variant='warning'
            title='If appropriate, consider another formulation'
            icon={<AlertIcon />}
          >
            This medication at this strength isn&apos;t covered by the patient&apos;s insurance and
            will require prior authorization. This may delay pick-up while the care team coordinates
            with the patient&apos;s insurance.
          </Alert>
        </Grid.Col>
      )}

      <Grid.Col span={6}>
        <TextInput
          {...form.getInputProps('medication_quantity')}
          label={`Dispense quantity ${
            full_medication?.DispenseUnitId
              ? ` (${DispenseUnitType[full_medication.DispenseUnitId]})`
              : ''
          }`}
          disabled={!medication}
          placeholder='e.g. 28'
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          {...form.getInputProps('medication_days_supply')}
          label='Days supply'
          disabled={!medication}
          placeholder='e.g. 7'
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Checkbox
          {...form.getInputProps('substitutionAllowed')}
          label='Allow substitution'
          checked={form.values.substitutionAllowed}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea
          {...form.getInputProps('directions')}
          minRows={2}
          autosize
          maxLength={DOSESPOT_DIRECTIONS_CHAR_LIMIT}
          label='Directions'
          placeholder='e.g. Place one and a half (1.5) films under tongue daily as instructed'
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          {...form.getInputProps('effective_date')}
          label='Effective date'
          placeholder='MM/DD/YYYY'
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Checkbox {...form.getInputProps('bridge')} label='This is a bridge prescription' />
      </Grid.Col>
      <Grid.Col span={12}>
        <PharmacySection
          pharmacy={pharmacy}
          pharmacyUpdateOnClick={pharmacyUpdateOnClick}
          error={form.errors.pharmacyId}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea
          label='Pharmacy notes'
          {...form.getInputProps('notes')}
          minRows={4}
          maxLength={DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT}
          placeholder='Justification for early refill request, justification for change in dose, stating if you are a licensed out of state prescriber, etc.'
        />
      </Grid.Col>
    </Grid>
  )
}

export default EditQueuedPrescriptionDrawer
