import { Stack, Table, Td, Th } from '@shared/components'
import { Page } from '../../components/templates/TDefault'
import { useLunaQuery } from '../../utils/hooks'

export const OrganizationTableContent = () => {
  const orgsQuery = useLunaQuery('GET /referralOrganizations')
  const orgs = orgsQuery.data?.data || []

  return (
    <Page title='Organizations'>
      <Stack spacing='lg' py='lg' p='lg' sx={{ flex: 1 }}>
        <Table
          striped
          withBorder
          verticalSpacing='sm'
          sx={({ radius, other: { sizes, colors } }) => ({
            tableLayout: 'auto',
            backgroundColor: colors.background[0],
            borderWidth: sizes.border.lg,
            borderRadius: radius.sm,
            borderCollapse: 'separate',
            borderStyle: 'solid',
            borderSpacing: '0',
            borderColor: colors.background[2],
          })}
        >
          <thead className='mantine'>
            <tr className='mantine'>
              <Th sortable={false}>Name</Th>
              <Th sortable={false}>Created at</Th>
              <Th sortable={false}>Total referrals</Th>
            </tr>
          </thead>
          <tbody className='mantine'>
            {orgs.map(org => (
              <tr key={org.name} className='mantine'>
                <Td>{org.name}</Td>
                <Td>{org.createdAt}</Td>
                <Td>{org.totalReferrals}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Stack>
    </Page>
  )
}
