import { AppShell, Flex } from '@shared/components'
import { hasGroupRole, hasRole } from '@shared/types'
import { cio, toTime } from '@shared/utils'
import { memo, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { IdlePromptModal } from './IdlePromptModal'
import ALargeLoadingSpinner from './components/atoms/ALargeLoadingSpinner'
import { EMRCalling } from './components/calling/EMRCalling'
import { Fallback } from './components/errors/Fallback'
import { ErrorBoundary } from './components/errors/errorBoundary'
import { IframeNavbar } from './components/templates/IframeNavbar'
import { Navbar } from './components/templates/Navbar'
import { useAuth } from './context/auth'
import PLanding from './pages/PLanding'
import { PLogin } from './pages/PLogin'
import PAdmin from './pages/admin/PAdmin'
import PBilling from './pages/billing/PBilling'
import { PCalendar } from './pages/calendar/PCalendar'
import PCareTeam from './pages/care_team/PCareTeam'
import { PTasks } from './pages/care_team/tasks/PTasks'
import { canViewIssues } from './pages/care_team/tasks/canViewIssues'
import { ClinicPage } from './pages/clinic/ClinicPage'
import { CommandPalette } from './pages/core/command_palette/CommandPalette'
import PEmployees from './pages/employees/PEmployees'
import { PEnrollmentPanel } from './pages/enrollment_panel/PEnrollmentPanel'
import POrganizations from './pages/organizations/POrganizations'
import PPatient from './pages/patient/PPatient'
import PPatients from './pages/patient/PPatients'
import PPatientsLegacy from './pages/patient/PPatientsLegacy'
import PRegistry from './pages/registry/PRegistry'
import * as FullStory from './utils/fullstory'
import { useFlags } from './utils/hooks'
import { OutageBannerProvider } from './utils/hooks/use-outage-banner'
import { SidePane, SidePaneProvider } from './utils/hooks/use-side-pane'
import { useToastNotifications } from './utils/hooks/use-toast-notifications'
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rudderanalytics: any
  }
}

const AuthAppShell = ({ children }: { children: React.ReactNode }) => {
  const { logOut } = useAuth()
  const [showIdlePrompt, setShowIdlePrompt] = useState(false)
  const { emrCalling } = useFlags()

  const idleTimer = useIdleTimer({
    crossTab: true,
    throttle: toTime('1 second').ms(),
    syncTimers: toTime('30 seconds').ms(),
    // 60 minutes of inactivity will log the user out
    timeout: toTime('60 minutes').ms(),
    // 5 minutes before the timeout, we'll prompt the user to see if they're still there
    promptBeforeIdle: toTime('5 minutes').ms(),
    onActive: () => {
      /*
       * If another browser tab shows the idle prompt and the user clicks "Yes, I'm here" there,
       * then this callback enusre that the idle prompt in the current tab is closed.
       */
      setShowIdlePrompt(false)
    },
    onPrompt: () => {
      setShowIdlePrompt(true)
    },
    onIdle: () => {
      FullStory.event('Idle User Logged Out')
      void logOut()
    },
  })

  // In Twilio Flex we hide the navbar to save on screen space
  const isInIframe = window !== window.parent

  return (
    <>
      <AppShell
        navbar={isInIframe ? <IframeNavbar /> : <Navbar />}
        padding={0}
        layout='alt'
        styles={{
          main: {
            display: 'flex',
            maxHeight: '100vh',
          },
        }}
        aside={<SidePane />}
      >
        <Flex
          sx={({ other: { colors } }) => ({
            flex: 1,
            flexGrow: 1,
            overflowX: 'hidden',
            backgroundColor: colors.background[0],
          })}
          direction='column'
        >
          {children}
        </Flex>
        <CommandPalette />
      </AppShell>
      {emrCalling && <EMRCalling />}
      <IdlePromptModal
        opened={showIdlePrompt}
        onClose={() => setShowIdlePrompt(false)}
        idleTimer={idleTimer}
        onLogout={logOut}
      />
    </>
  )
}

const AppContent = () => {
  const { isLoading, isAuthenticated, isAuthorized, currentUser } = useAuth()
  const location = useLocation()
  const { patientsTable } = useFlags()

  useEffect(() => {
    // This lets customer.io know which page the user has visited. Used for in-app messaging.
    cio.page()
  }, [location])

  if (isLoading) {
    return (
      <main className='flex items-center justify-center h-screen'>
        <ALargeLoadingSpinner />
      </main>
    )
  }

  if (isAuthenticated && isAuthorized) {
    return (
      <ErrorBoundary fallback={<Fallback />}>
        <SidePaneProvider>
          <AuthAppShell>
            <OutageBannerProvider>
              <Routes>
                <Route
                  path='/patients/search'
                  element={patientsTable ? <PPatients /> : <PPatientsLegacy />}
                />
                <Route path='/patients/:patientID/*' element={<PPatient />} />
                <Route
                  path='/patients'
                  element={patientsTable ? <PPatients /> : <PPatientsLegacy />}
                />
                <Route path='/employees/:employeeID' element={<PEmployees />} />
                <Route
                  path='/employees'
                  element={<Navigate replace to={`/employees/${currentUser?.oid}`} />}
                />
                <Route path='/visits/:providerID' element={<PCalendar />} />
                <Route path='/visits' element={<PCalendar />} />
                <Route path='/billing/*' element={<PBilling />} />
                <Route path='/care-team/*' element={<PCareTeam />} />
                <Route path='/admin/*' element={<PAdmin />} />
                <Route
                  path='/twilio-flex-landing'
                  element={
                    hasRole(currentUser, 'pc', 'spc') ? (
                      <Navigate replace to='/visits' />
                    ) : (
                      <Navigate replace to='/issues/open' />
                    )
                  }
                />
                {(hasRole(currentUser, 'stn', 'tn', 'sncm', 'ncm', 'ncm_tn') ||
                  hasGroupRole(currentUser, 'admin', 'hqEmployee') ||
                  currentUser.isPsychConsultant) && (
                  <Route path='/registry/*' element={<PRegistry />} />
                )}
                {canViewIssues(currentUser) && (
                  <Route path='/issues' element={<Navigate replace to='/issues/open' />} />
                )}
                {canViewIssues(currentUser) && <Route path='/issues/:queue' element={<PTasks />} />}
                {hasGroupRole(
                  currentUser,
                  'admin',
                  'engineer',
                  'enrollmentCoordinator',
                  'leadEnrollmentCoordinator',
                ) && <Route path='/enrollment-panel/*' element={<PEnrollmentPanel />} />}
                <Route path='/clinic' element={<ClinicPage />} />
                <Route path='/organizations/*' element={<POrganizations />} />
                <Route path='*' element={<PLanding />} />
              </Routes>
            </OutageBannerProvider>
          </AuthAppShell>
        </SidePaneProvider>
      </ErrorBoundary>
    )
  }

  if (isAuthenticated && !isAuthorized) {
    return (
      <main className='flex items-center justify-center h-screen'>
        <div className='max-w-md w-full'>
          <p className='text-base text-center leading-8'>
            No account found. Please contact your hiring manager to create an account or reach out
            to the{' '}
            <a href='slack://channel?team=TRN06CLEM&id=C01083KPYJX'>#clinical-product-support</a>{' '}
            channel in Slack for more support.
          </p>
        </div>
      </main>
    )
  }

  return (
    <Routes>
      <Route path='/login' element={<PLogin />} />
      <Route path='*' element={<Navigate state={{ from: location }} replace to='/login' />} />
    </Routes>
  )
}

/**
 * We memoize the AppContent component to prevent unnecessary re-renders
 * that are caused by the refetching interval in use-toast-notifications.tsx
 */
const MemoizedAppContent = memo(AppContent)

const App = () => {
  useToastNotifications()
  return <MemoizedAppContent />
}

export default App
