import { useForm } from '@mantine/form'
import { useCounter, useDisclosure } from '@mantine/hooks'
import {
  BannerPortal,
  Box,
  CheckCircleIcon,
  EM_DASH,
  Flex,
  Group,
  HelpCircleIcon,
  PlusIcon,
  PrimaryButton,
  Select,
  SlashIcon,
  Stack,
  Table,
  Td,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
  UserXIcon,
} from '@shared/components'
import {
  activeStates,
  EmrApi,
  hasGroupRole,
  isClinician,
  Patient,
  PatientStatus,
} from '@shared/types'
import { name } from '@shared/utils'
import orderBy from 'lodash/orderBy'
import range from 'lodash/range'
import sum from 'lodash/sum'
import values from 'lodash/values'
import { useEffect, useReducer, useRef } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'
import LoadingRow from '../../components/atoms/LoadingRow'
import NoDataRow from '../../components/atoms/NoDataRow'
import { Page } from '../../components/templates/TDefault'
import { useAuth } from '../../context/auth'
import { useEmployees } from '../../utils/hooks'
import { postFlexChartViewedMessage } from '../../utils/utils'
import SearchBar from '../care_team/irq/SearchBar'
import { Pagination } from '../care_team/tasks/Pagination'
import { AddPatientDrawer } from './AddPatientDrawer'

const InsuranceStatusIcon = ({ status }: { status?: 'verified' | 'unverified' | 'none' }) => {
  switch (status) {
    case 'verified':
      return (
        <Tooltip label='Verified' offset={15}>
          <CheckCircleIcon color={colors => colors.success[0]} size='lg' />
        </Tooltip>
      )
    case 'unverified':
      return (
        <Tooltip label='Unverified' offset={15}>
          <HelpCircleIcon color={colors => colors.warning[0]} size='lg' />
        </Tooltip>
      )
    default:
      return (
        <Tooltip label='No Info Uploaded' offset={15}>
          <SlashIcon color={colors => colors.background[4]} size='lg' />
        </Tooltip>
      )
  }
}

const PAGE_SIZE = 50

type PatientStatusOption = { value: PatientStatus; text: string }

const PATIENT_STATUS_OPTIONS: PatientStatusOption[] = [
  { value: 'in treatment', text: 'In treatment' },
  { value: 'discharged', text: 'Discharged' },
  { value: 'paused', text: 'Paused' },
  { value: 'needs review', text: 'Needs review' },
  { value: 'not responding', text: 'Not responding' },
  { value: 'candidate', text: 'Candidate' },
  { value: 'lead', text: 'Lead' },
  { value: 'ineligible', text: 'Ineligible' },
]

export type SortColumn =
  | 'patientName'
  | 'prescribingClinician'
  | 'clinicalCareManager'
  | 'status'
  | 'careLevel'
  | 'insurance'
  | 'state'

const getPatientCountString = (patientCount: number): string => {
  if (patientCount === 0) {
    return 'No patients'
  }
  if (patientCount === 1) {
    return '1 patient'
  }
  return `${patientCount} patients`
}

const paginationReducer = (
  state: { offset: number },
  action:
    | {
        type: 'NEXT_PAGE' | 'PREV_PAGE'
        token: string
      }
    | { type: 'RESET' },
): { nextPageToken?: string; prevPageToken?: string; offset: number } => {
  if (action.type === 'NEXT_PAGE') {
    return { nextPageToken: action.token, offset: state.offset + PAGE_SIZE }
  }
  if (action.type === 'PREV_PAGE') {
    return { prevPageToken: action.token, offset: state.offset - PAGE_SIZE }
  }
  if (action.type === 'RESET') {
    return { offset: 0 }
  }
  return state
}

const NUMBER_OF_LOADING_ROWS = 6

const TABLE_COLUMNS = [
  'Patient name',
  'Prescribing clinician',
  'Clinical care manager',
  'Status',
  'Care level',
  'Insurance',
  'State',
] as const

export type OrderBy = {
  key: OrderByKey
  direction: 'ASC' | 'DESC'
}

type PPatientsProps = {
  query: string
  patientStatus: PatientStatus | ''
  providerID: string
  state: string
  sortColumn: SortColumn
  sortDirection: 'asc' | 'desc'
}

type OrderByKey = keyof EmrApi['GET /patients']['res']['items'][number]

const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const PPatients = () => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const searchQueryParam = params.get('q')
  const navigate = useNavigate()
  const [paginationState, updatePagination] = useReducer(paginationReducer, { offset: 0 })
  const pageContainer = useRef(null)
  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const medicalProviders = isClinician(employeesQuery.data || [])
  const [pageNumber, pageNumberActions] = useCounter(1)
  const { offset, ...tokenParams } = paginationState
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm<PPatientsProps>({
    initialValues: {
      query: '',
      patientStatus: hasGroupRole(currentUser, 'enrollmentCoordinator') ? '' : 'in treatment',
      providerID: isClinician(currentUser) ? currentUser.oid : '',
      state: 'All States',
      sortColumn: 'patientName',
      sortDirection: 'asc',
    },
  })

  const patientsQuery = useQuery(
    ...emrApi.getQuery('GET /patients', {
      query: {
        q: searchQueryParam ?? undefined,
        limit: `${PAGE_SIZE}`,
        employeeId: form.values.providerID,
        patientStatus: form.values.patientStatus ? [form.values.patientStatus] : [],
        orderBy: 'name',
        nextPageToken: tokenParams.nextPageToken,
        prevPageToken: tokenParams.prevPageToken,
        state: form.values.state === 'All States' ? undefined : form.values.state,
        withInsuranceStatus: 'yes',
      },
    }),
    {
      onSuccess(data) {
        if (!data.items.length) {
          /*
           * This message prompts Flex to go ahead and load the conversation of a phone number
           * that was just searched but had no patient results
           */
          postFlexChartViewedMessage(searchQueryParam ?? '')
        }
      },
    },
  )

  const patients = patientsQuery.data?.items ?? []

  // Check location on mount and trigger search if present.
  useEffect(() => {
    if (searchQueryParam) {
      form.setValues({ query: searchQueryParam })
    }
  }, [searchQueryParam])

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }, [offset])

  useEffect(() => {
    updatePagination({ type: 'RESET' })
  }, [form.values.patientStatus, form.values.state, form.values.providerID])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!currentUser) {
      return
    }

    analytics.page({
      name: 'Patients',
      properties: {
        userId: currentUser.oid,
        userName: currentUser.name,
      },
    })
  }, [currentUser])

  const getProviderPatientCount = () => {
    const selectedProvider = medicalProviders.find(
      provider => provider.oid === form.values.providerID,
    )
    if (form.values.patientStatus) {
      return selectedProvider?.patientCounts?.[form.values.patientStatus] || 0
    }
    return sum(values(selectedProvider?.patientCounts))
  }

  const onCancel = () => {
    form.setValues({ query: '' })
    navigate({ pathname: '/patients' })
  }

  const onSubmit = () => {
    if (form.values.query) {
      navigate({
        pathname: '/patients/search',
        search: `?q=${form.values.query}`,
      })
    } else {
      navigate({ pathname: '/patients' })
    }
  }

  const handleSortingChange = (columnKey: SortColumn) => {
    if (form.values.sortColumn === columnKey) {
      form.setFieldValue('sortDirection', form.values.sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      form.setValues({ sortColumn: columnKey, sortDirection: 'asc' })
    }
  }

  const abbreviateState = (state: string) => {
    const stateInfo = activeStates.filter(i => i.state === state)[0]
    return stateInfo ? stateInfo.abbr : state
  }

  const sortColumnMap = {
    patientName: (patient: Patient) =>
      `${patient.personalData?.lastName || ''} ${patient.personalData?.firstName || ''}`.trim(),
    prescribingClinician: (patient: Patient) => patient.primaryClinician?.name || '',
    clinicalCareManager: (patient: Patient) => patient.nurseCareManager?.name || '',
    status: (patient: Patient) => patient.statuses?.patient || '',
    careLevel: (patient: Patient) => patient.statuses?.levelOfCare || '',
    insurance: (patient: Patient) =>
      patient.insuranceData?.hasInsurance ? 'Insured' : 'Uninsured',
    state: (patient: Patient) => patient.homeData?.state || patient.shippingData?.state || '',
  }

  const getAccessor = (column: SortColumn): ((patient: Patient) => string) =>
    sortColumnMap[column] || (() => '')

  const sortedPatients = orderBy(
    patients,
    [getAccessor(form.values.sortColumn)],
    [form.values.sortDirection.toLowerCase() as 'asc' | 'desc'],
  )

  return (
    <Page title='Patients'>
      <Stack spacing='lg' py='lg' p='lg' sx={{ flex: 1 }}>
        <BannerPortal />
        <AddPatientDrawer opened={opened} onClose={close} />
        <Box
          mih='100vh'
          pos='relative'
          px='xs'
          pb={64}
          ref={pageContainer}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Group pt={16} px={8} pb={32} position='apart'>
            <Group>
              <Box>
                <SearchBar
                  value={form.values.query}
                  placeholder='Search by patient name...'
                  onChange={val => form.setValues({ query: val })}
                  onEnter={onSubmit}
                  onClear={onCancel}
                />
              </Box>
              <Box w='full'>
                <Select
                  data={medicalProviders.map(provider => ({
                    value: provider.oid,
                    label: provider.name,
                  }))}
                  searchable
                  clearable
                  placeholder='All Clinicians'
                  {...form.getInputProps('providerID')}
                />
              </Box>
              <Box w='full'>
                <Select
                  data={PATIENT_STATUS_OPTIONS.map(option => ({
                    value: option.value,
                    label: option.text,
                  }))}
                  searchable
                  clearable
                  placeholder='All Statuses'
                  {...form.getInputProps('patientStatus')}
                />
              </Box>
              <Box w='full'>
                <Select
                  data={activeStates.map(option => ({
                    value: option.state,
                    label: option.state,
                  }))}
                  searchable
                  clearable
                  placeholder='All States'
                  {...form.getInputProps('state')}
                />
              </Box>
              {form.values.providerID && (
                <Text color={({ text }) => text[1]}>
                  {form.values.state === 'All States'
                    ? getPatientCountString(getProviderPatientCount())
                    : null}
                </Text>
              )}
            </Group>
            {hasGroupRole(currentUser, 'admin', 'engineer', 'enrollmentCoordinator') && (
              <PrimaryButton onClick={open} leftIcon={<PlusIcon />}>
                Patient
              </PrimaryButton>
            )}
          </Group>
          <Table
            striped
            withBorder
            verticalSpacing='sm'
            sx={({ radius, other: { sizes, colors } }) => ({
              tableLayout: 'auto',
              backgroundColor: colors.background[0],
              borderWidth: sizes.border.lg,
              borderRadius: radius.sm,
              borderCollapse: 'separate',
              borderStyle: 'solid',
              borderSpacing: '0',
              borderColor: colors.background[2],
            })}
          >
            <thead className='mantine'>
              <tr className='mantine'>
                {TABLE_COLUMNS.map((header, index) => {
                  const columnKey = [
                    'patientName',
                    'prescribingClinician',
                    'clinicalCareManager',
                    'status',
                    'careLevel',
                    'insurance',
                    'state',
                  ][index]
                  return (
                    <Th
                      sortable
                      sorted={form.values.sortColumn === columnKey}
                      reversed={
                        form.values.sortColumn === columnKey && form.values.sortDirection === 'asc'
                      }
                      onSort={() => handleSortingChange(columnKey as SortColumn)}
                      key={header}
                    >
                      {header}
                    </Th>
                  )
                })}
              </tr>
            </thead>
            <tbody className='mantine'>
              {patientsQuery.isLoading &&
                range(0, NUMBER_OF_LOADING_ROWS + 1).map(i => (
                  <LoadingRow key={i} headersLength={TABLE_COLUMNS.length} />
                ))}
              {!patientsQuery.isLoading && sortedPatients.length === 0 && (
                <NoDataRow
                  message='No winback patients found'
                  headersLength={TABLE_COLUMNS.length}
                />
              )}
              {!patientsQuery.isLoading &&
                sortedPatients.length > 0 &&
                sortedPatients.map(patient => {
                  return (
                    <tr key={patient.oid}>
                      {TABLE_COLUMNS.map(col => {
                        const renderCellContent = () => {
                          switch (col) {
                            case 'Patient name':
                              return (
                                <TertiaryButton component={Link} to={`/patients/${patient.oid}`}>
                                  {name({
                                    first: patient.personalData?.firstName,
                                    last: patient.personalData?.lastName,
                                  }).lastCommaFirst() || EM_DASH}
                                </TertiaryButton>
                              )
                            case 'Prescribing clinician':
                              return <Text>{patient.primaryClinician?.name || EM_DASH}</Text>
                            case 'Clinical care manager':
                              return <Text>{patient.nurseCareManager?.name || EM_DASH}</Text>
                            case 'Status':
                              return (
                                <Text>{sentenceCase(patient.statuses.patient) || EM_DASH}</Text>
                              )
                            case 'Care level':
                              return (
                                <Text style={{ textTransform: 'capitalize' }}>
                                  {patient.statuses.levelOfCare || EM_DASH}
                                </Text>
                              )
                            case 'Insurance':
                              return <InsuranceStatusIcon status={patient.insuranceStatus} />
                            case 'State':
                              return (
                                <Text>
                                  {abbreviateState(
                                    patient.homeData?.state || patient.shippingData?.state || '',
                                  ) || EM_DASH}
                                </Text>
                              )
                            default:
                              return null
                          }
                        }
                        return <Td key={col}>{renderCellContent()}</Td>
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </Table>
          {patients.length === 0 && !patientsQuery.isLoading && (
            <Flex justify='center' align='center' direction='column' sx={{ height: '100%' }}>
              <UserXIcon size='lg' />
              <Text bold>No patients found</Text>
            </Flex>
          )}
          {patients.length > 0 && (
            <Flex mx='lg' justify='flex-end' sx={{ padding: '2rem' }}>
              <Pagination
                page={pageNumber}
                pageSize={PAGE_SIZE}
                totalItems={patientsQuery.data?.totalCount ?? 0}
                onDecrement={() => {
                  pageNumberActions.decrement()
                  updatePagination({
                    type: 'PREV_PAGE',
                    token: patientsQuery.data?.prevPageToken ?? '',
                  })
                }}
                onIncrement={() => {
                  pageNumberActions.increment()
                  updatePagination({
                    type: 'NEXT_PAGE',
                    token: patientsQuery.data?.nextPageToken ?? '',
                  })
                }}
              />
            </Flex>
          )}
        </Box>
      </Stack>
    </Page>
  )
}

export default PPatients
