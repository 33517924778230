import { Encounter, EncounterWithErrors } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class EncountersApi extends BaseApi {
  // Deprecated - use luna 'GET /encounters' instead
  getEncounters = (params: {
    filter: string
    patientId?: string
    order: string
    per: number
    startAfter: string | undefined
    payerId: string
  }) => {
    return this.json<EncounterWithErrors[]>({
      method: 'GET',
      url: '/encounters',
      params,
    })
  }

  getEncounterWithErrorsById = ({ encounterId }: { encounterId: string }) => {
    return this.json<EncounterWithErrors>({
      method: 'GET',
      url: `/encounters/${encounterId}`,
    })
  }

  submitToCandid = ({
    encounterId,
    reviewerId,
    reviewedDate,
  }: {
    encounterId: string
    reviewerId: string
    reviewedDate: string
  }) => {
    return this.json<Promise<void>>({
      method: 'POST',
      url: `/candid`,
      data: { encounterId, reviewerId, reviewedDate },
    })
  }

  updateEncounter = ({ data }: { data: { encounter: Partial<Encounter>; id: string } }) => {
    return this.json<Promise<void>>({
      method: 'PUT',
      url: `/encounters`,
      data,
    })
  }
}
