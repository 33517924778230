import {
  BookIcon,
  Box,
  CalendarIcon,
  Center,
  ClockIcon,
  ColumnsIcon,
  GridIcon,
  HelpCircleIcon,
  HomeIcon,
  Indicator,
  ListIcon,
  LogOutIcon,
  Navbar as MantineNavbar,
  OLogo,
  SettingsIcon,
  Stack,
  TagIcon,
  UnstyledButton,
  UserIcon,
  UsersIcon,
} from '@shared/components'
import { Employee, hasGroupRole, hasRole, isAdmin, isClinician, isCoordinator } from '@shared/types'
import { useAuth } from '../../context/auth'
import { IssueDrawer } from '../../pages/care_team/tasks/IssueDrawer'
import { canViewIssues } from '../../pages/care_team/tasks/canViewIssues'
import { useFlags } from '../../utils/hooks'
import { useAccess } from '../../utils/hooks/use-access'
import { useOpenIssues } from '../../utils/hooks/use-open-issues'
import { useSidePane } from '../../utils/hooks/use-side-pane'
import { ClinicianStatus } from '../ClinicianStatus'
import MenuItem from './MenuItem'

type MenuItem = {
  to: string
  label: string
  visible: (currentUser?: Employee) => boolean
  icon: React.ReactNode
}

export const Navbar = () => {
  const access = useAccess()
  const { logOut } = useAuth()
  const { currentUser } = useAuth()
  const { count: openIssueCount } = useOpenIssues()
  const { presentPane, hidePane } = useSidePane()
  const { referralOrganizations } = useFlags()

  const menu: MenuItem[] = [
    {
      to: '/visits',
      label: 'Calendar',
      visible: () => true,
      icon: <CalendarIcon />,
    },
    {
      to: '/patients',
      label: 'Patients',
      visible: () => true,
      icon: <UsersIcon />,
    },
    {
      to: '/issues',
      label: openIssueCount ? `${openIssueCount} open/unassigned issues` : 'Issue tracker',
      visible: () => canViewIssues(currentUser),
      icon: openIssueCount ? (
        <Indicator label={openIssueCount} color='red' offset='md'>
          <ColumnsIcon />
        </Indicator>
      ) : (
        <ColumnsIcon />
      ),
    },
    {
      to: '/employees',
      label: 'Employees',
      visible: () => true,
      icon: <ListIcon />,
    },
    {
      to: '/billing',
      label: 'Billing',
      visible: currentUser =>
        Boolean(currentUser && (isAdmin(currentUser) || isCoordinator(currentUser))),
      icon: <TagIcon />,
    },
    {
      to: '/registry',
      label: 'Registry',
      visible: currentUser =>
        hasRole(currentUser, 'stn', 'tn', 'sncm', 'ncm', 'ncm_tn') ||
        hasGroupRole(currentUser, 'admin', 'hqEmployee') ||
        Boolean(currentUser?.isPsychConsultant),
      icon: <GridIcon />,
    },
    {
      to: '/enrollment-panel',
      label: 'Enrollment panel',
      visible: currentUser =>
        hasGroupRole(currentUser, 'admin', 'engineer', 'enrollmentCoordinator'),
      icon: <BookIcon />,
    },
    {
      to: '/clinic',
      label: 'Clinic',
      visible: () => access.dropInClinic,
      icon: <ClockIcon />,
    },
    {
      to: '/organizations',
      label: 'Referral organizations',
      visible: () => referralOrganizations,
      icon: <HomeIcon />,
    },
  ]

  return (
    <MantineNavbar
      p='xs'
      width={{ base: 64 }}
      sx={theme => ({ backgroundColor: theme.other.colors.background[7], border: 'unset' })}
    >
      <MantineNavbar.Section pt='sm' grow>
        <Box pt='sm' w='100%'>
          <Center>
            <OLogo height={16} width={16} />
          </Center>
        </Box>
        <Stack pt='lg' spacing='md'>
          {menu
            .filter(item => item.visible(currentUser))
            .map(item => (
              <MenuItem key={item.label} {...item} />
            ))}
        </Stack>
      </MantineNavbar.Section>
      <MantineNavbar.Section pt='sm' pb='md'>
        <Stack spacing='md'>
          <MenuItem
            label='Report tech issue'
            icon={<HelpCircleIcon />}
            onClick={() => {
              presentPane({
                key: 'report-tech-issue',
                content: (
                  <IssueDrawer
                    step='add'
                    patient={null}
                    issueType='emr_tech_issue'
                    onClose={hidePane}
                  />
                ),
              })
            }}
          />
          <MenuItem label='Profile' icon={<UserIcon />} to='/employees' />
          <MenuItem label='Admin' icon={<SettingsIcon />} to='/admin' />
          <UnstyledButton onClick={logOut}>
            <MenuItem label='Sign out' icon={<LogOutIcon />} />
          </UnstyledButton>
          {hasGroupRole(currentUser, 'ccm', 'primaryClinician') && isClinician(currentUser) && (
            <ClinicianStatus />
          )}
        </Stack>
      </MantineNavbar.Section>
    </MantineNavbar>
  )
}
