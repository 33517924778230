import {
  AlertIcon,
  Banner,
  ChevronDownIcon,
  Flex,
  Menu,
  PrimaryButton,
  Stack,
  Text,
} from '@shared/components'
import { AppointmentTypeString } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import { useParams } from 'react-router-dom'
import { useEmrQuery, useFlags, useLunaQuery } from '../../../utils/hooks'
import { useAccess } from '../../../utils/hooks/use-access'
import { usePatient } from '../PPatientContext'
import { UpcomingAppointmentContent } from '../journey/content/UpcomingAppointmentContent'
import { VisitHoldContent } from '../journey/content/VisitHoldContent'
import { useInitialBhcm } from '../use-initial-bhcm'
import { VisitNoteSection } from './VisitNoteSection'

type UpcomingVisitsSectionProps = {
  isNoteClosed?: boolean | null
  isSidebar: boolean
}

type OnScheduleVisitType = Extract<
  AppointmentTypeString,
  'Follow-Up Visit' | 'Check-In Call' | 'Induction Check-In Call' | 'UDS Visit'
>

const UpcomingVisitsSection = ({ isNoteClosed, isSidebar }: UpcomingVisitsSectionProps) => {
  const { visitHoldsV2 } = useFlags()
  const { canScheduleVisitHolds } = useAccess()
  const { shouldScheduleInitialBhcmVisit, initialBhcmVisitTypeName } = useInitialBhcm()
  const { setModal, patientQuery } = usePatient()
  const { visitID = '' } = useParams<{ visitID: string }>()
  const patient = patientQuery?.data

  const onScheduleVisit = (visitType: OnScheduleVisitType | null) => {
    return () => {
      setModal({
        type: 'scheduling-flow',
        visitType: visitType ? [visitType] : null,
      })
    }
  }

  const appointmentsQuery = useEmrQuery(
    'GET /patient/:patientId/appointments',
    {
      params: {
        patientId: patient?.oid || '',
      },
    },
    {
      enabled: Boolean(patient?.oid),
    },
  )

  const futureAppointments = (appointmentsQuery.data || [])
    .sort(sortBy({ key: 'datetime' }))
    .filter(appointment => {
      return (
        appointment.id !== parseInt(visitID) &&
        !appointment.canceled &&
        dayjs(appointment.datetime).isSameOrAfter(dayjs())
      )
    })

  const visitHoldsQuery = useLunaQuery(
    'GET /scheduling/patients/:patientId/holds',
    {
      params: {
        patientId: patient?.oid || '',
      },
    },
    {
      enabled: Boolean(patient?.oid),
    },
  )

  const visitHoldsData = visitHoldsQuery.data?.data

  const hasUpcomingHold = visitHoldsData && visitHoldsData.length > 0

  if (isNoteClosed || isSidebar) {
    return null
  }

  const visitHoldSchedulingEnabled = visitHoldsV2 && canScheduleVisitHolds
  return (
    <VisitNoteSection title='Upcoming Visits'>
      {/* Breathing room for the schedule visit dropdown */}
      <Stack pb={150}>
        <Stack spacing='sm'>
          {shouldScheduleInitialBhcmVisit && (
            <Banner
              label={`Schedule ${patient?.personalData.firstName} for a ${initialBhcmVisitTypeName} with their CCM for improved billing`}
              type='warning'
              mb='md'
            />
          )}
          {/* The calendar holds query returns a list of holds, but patients should only have
          a maximum of one open calendar hold */}
          {hasUpcomingHold &&
            visitHoldsData.map(visitHold => (
              <VisitHoldContent key={visitHold.oid} visitHold={visitHold} />
            ))}
          {futureAppointments
            ?.filter(appt => appt.calendarID)
            .map(appointment => {
              if (appointment.calendarID) {
                return (
                  <UpcomingAppointmentContent
                    key={appointment.id}
                    appointment={appointment}
                    displayDate
                  />
                )
              }
              return null
            })}
        </Stack>
        {futureAppointments && futureAppointments.length === 0 && !hasUpcomingHold && (
          <Flex gap='sm' align='center'>
            <AlertIcon color={c => c.actions[1]} size='md' />
            <Text>No visits scheduled</Text>
          </Flex>
        )}
        {visitHoldSchedulingEnabled ? (
          <Menu position='bottom' width='target'>
            <Menu.Target>
              <PrimaryButton mt='sm' size='sm' rightIcon={<ChevronDownIcon />}>
                Schedule visit
              </PrimaryButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={onScheduleVisit('Follow-Up Visit')}>Follow-up</Menu.Item>
              <Menu.Item onClick={onScheduleVisit('Check-In Call')}>Check-in</Menu.Item>
              <Menu.Item onClick={onScheduleVisit('Induction Check-In Call')}>
                Induction Check-in
              </Menu.Item>
              <Menu.Item onClick={onScheduleVisit('UDS Visit')}>UDS</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <PrimaryButton mt='sm' onClick={onScheduleVisit(null)}>
            Schedule visit
          </PrimaryButton>
        )}
      </Stack>
    </VisitNoteSection>
  )
}

export default UpcomingVisitsSection
