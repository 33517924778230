import { Navigate, Route, Routes } from 'react-router-dom'
import { OrganizationContent } from './OrganizationContent'
import { OrganizationTableContent } from './OrganizationTableContent'

const POrganizations = () => {
  return (
    <Routes>
      <Route path='/:organizationId' element={<OrganizationContent />} />
      <Route path='/' element={<OrganizationTableContent />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}

export default POrganizations
