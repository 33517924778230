import { Stack } from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import { useState } from 'react'
import { Page } from '../../../components/templates/TDefault'
import BillingHeaderContent from '../BillingHeaderContent'
import { PayerEnrollmentsTable } from './BillingInsuranceMatchingTable'
import { EditPayerDrawer } from './EditPayerDrawer/EditPayerDrawer'

export type PayerEnrollmentListItem = EmrLunaApi['GET /payer-enrollments']['res']['data'][0]

export const BillingInsuranceMatching = () => {
  const [payer, setPayer] = useState<PayerEnrollmentListItem | null>(null)

  return (
    <>
      <Page
        title='Billing'
        headerContent={<BillingHeaderContent selectedPage='Payer configuration' />}
        pushHeaderContent={false}
      >
        <Stack>
          <PayerEnrollmentsTable setPayer={setPayer} />
        </Stack>
      </Page>
      <EditPayerDrawer
        opened={Boolean(payer)}
        onClose={() => {
          setPayer(null)
        }}
        payer={payer}
      />
    </>
  )
}
