import { AlertIcon, Pill, Select, Stack } from '@shared/components'
import { EmrLunaApi, EncounterStatuses } from '@shared/types'
import { dayjs, outOfNetworkPlans } from '@shared/utils'
import cn from 'classnames'
import orderBy from 'lodash/orderBy'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import AGeneralTablePaginationFooter from '../../../components/atoms/AGeneralTablePaginationFooter'
import {
  useInvalidateLunaQuery,
  useLunaQuery,
  useTypedHistory,
  useTypedParams,
} from '../../../utils/hooks'
import ListItem from '../../care_team/irq/ListItem'
import SearchBar from '../../care_team/irq/SearchBar'
import { PayerIdentification, reviewableStatuses } from './BillingEncounters'

const ALL_PLANS_GROUP_NAME = 'All plans' as const
const IN_NETWORK_GROUP_NAME = 'In Network' as const
const OUT_OF_NETWORK_GROUP_NAME = 'Out of Network' as const

const PLAN_GROUP_NAMES = [
  ALL_PLANS_GROUP_NAME,
  IN_NETWORK_GROUP_NAME,
  OUT_OF_NETWORK_GROUP_NAME,
] as const

type EncounterPlanGroupName = (typeof PLAN_GROUP_NAMES)[number]

type Encounter = EmrLunaApi['GET /encounters']['res']['data']['encounters'][0]

type EncounterListProps = {
  count: number
  encountersPerPage: number
  setPage: (input: number) => void
  setIndex: (input: number) => void
  page: number
  encounters: Encounter[]
  setEncounters: (encounters: Encounter[]) => void
  setCursors: Dispatch<SetStateAction<string[]>>
  payerIdentification: PayerIdentification | undefined
  setPayerIdentification: ({ id, type }: PayerIdentification) => void
}

const EncounterList = ({
  count,
  encountersPerPage,
  setPage,
  page,
  setIndex,
  encounters,
  setEncounters,
  setCursors,
  payerIdentification,
  setPayerIdentification,
}: EncounterListProps) => {
  const queryClient = useQueryClient()
  const { replace } = useTypedHistory()
  const {
    pathParams: { status = '', encounterId = '' },
  } = useTypedParams('/billing/encounters/:status/:encounterId')
  const [searching, setSearching] = useState(false)
  const [query, setQuery] = useState('')
  const searchLimit = 1000
  const payerEnrollmentsQuery = useLunaQuery('GET /payer-enrollments', {})
  const payerEnrollments = orderBy(payerEnrollmentsQuery.data?.data || [], d => d.payerName, 'asc')

  const allPlans = {
    label: 'All plans',
    value: '',
    group: ALL_PLANS_GROUP_NAME,
  }

  const inNetworkPlans = payerEnrollments
    .filter(payer => payer.status === 'in_network')
    .map(payer => ({
      label: payer.payerName,
      value: payer.oid,
    }))

  /*
   * Groups are used to display IIN and OON plans separately in the dropdown
   * And also to filter the encounters by payerEnrollmentId (IIN) or payerId (OON)
   */
  const allPlanOptions: { label: string; value: string; group: EncounterPlanGroupName }[] = [
    allPlans,
    ...inNetworkPlans.map(plan => ({ ...plan, group: IN_NETWORK_GROUP_NAME })),
    ...outOfNetworkPlans.map(plan => ({ ...plan, group: OUT_OF_NETWORK_GROUP_NAME })),
  ]

  const invalidateLunaQuery = useInvalidateLunaQuery()

  useEffect(() => {
    setQuery('')
    setSearching(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useLunaQuery(
    'GET /encounters',
    {
      query: {
        status: status as EncounterStatuses,
        patientId: query,
        order: reviewableStatuses.includes(status as EncounterStatuses) ? 'asc' : 'desc',
        limit: searchLimit.toString(),
        ...(payerIdentification ? { [payerIdentification.type]: payerIdentification.id } : {}),
      },
    },
    {
      enabled: query.length > 0 || searching,
      onSuccess: ({ data }) => {
        setEncounters(data.encounters)
      },
    },
  )

  const onSubmit = () => {
    setSearching(true)
  }

  return (
    <>
      <Stack spacing='sm' mb='sm'>
        <Select
          searchable
          placeholder='Filter by plan name'
          value={payerIdentification?.id}
          onChange={value => {
            const option = allPlanOptions.find(opt => opt.value === value)
            if (value && option?.group === IN_NETWORK_GROUP_NAME) {
              setPayerIdentification({ id: value, type: 'payerEnrollmentId' })
            } else if (value && option?.group === OUT_OF_NETWORK_GROUP_NAME) {
              setPayerIdentification({ id: value, type: 'payerId' })
            } else {
              setPayerIdentification({ id: '', type: 'payerId' })
            }
            setIndex(0)
            setPage(0)
            setCursors([])
            void invalidateLunaQuery('GET /encounters')
          }}
          data={allPlanOptions}
        />
        <SearchBar
          placeholder='Search by patient ID'
          onEnter={onSubmit}
          onChange={setQuery}
          value={query}
          onClear={() => {
            setQuery('')
            setSearching(false)
            setPage(0)
            setCursors([])
            void invalidateLunaQuery('GET /encounters')
          }}
        />
      </Stack>
      <ul className='relative z-0 divide-y divide-gray-200 border-gray-200 mb-6 col-span-2'>
        {encounters.map((encounter: Encounter) => (
          <ListItem
            key={encounter.oid}
            onClick={e => {
              const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
                params: { encounterId: encounter.oid },
              })
              queryClient.setQueryData(encounterQueryKey, (cachedEncounterData?: Encounter) => {
                if (cachedEncounterData) {
                  return cachedEncounterData
                }
                return encounter
              })
              /*
               * when an encounter status is changed, it'll no longer exist on the list,
               * so this allows for immediately opening the next encounter
               */
              setIndex(encounters.indexOf(encounter))
              replace(`/billing/encounters/${status as EncounterStatuses}/${encounter.oid}`)
              e.stopPropagation()
            }}
            focused={encounterId === encounter.oid}
          >
            <div className='flex row justify-between pb-2'>
              <p className='flex row'>
                <span
                  className={cn('flex row items-center', {
                    'font-semibold': encounterId === encounter.oid,
                  })}
                >
                  {encounter.patient.last_name}, {encounter.patient.first_name}
                </span>
              </p>
              <p className='relative group flex items-center'>
                <span className='text-xs font-md flex row space-x-2'>
                  {encounter.appointment_type === 'Collaborative Care Management' && (
                    <Pill
                      status='none'
                      variant='filled'
                    >{`CoCM - ${encounter.patient.address?.state}`}</Pill>
                  )}
                  {encounter?.errors && encounter?.errors?.length > 0 && (
                    <Pill status='error' variant='filled'>
                      <AlertIcon />
                    </Pill>
                  )}
                </span>
              </p>
            </div>
            <div className='hidden sm:flex flex-row flex-shrink-0 text-xs justify-between'>
              <div className='flex items-center'>
                {dayjs(encounter.date_of_service).add(1, 'week').isBefore(dayjs()) &&
                  reviewableStatuses.includes(status as EncounterStatuses) && (
                    <AlertIcon color={colors => colors.warning[0]} />
                  )}
                <div className='ml-1'>{dayjs(encounter.date_of_service).format('MM/DD/YYYY')}</div>
              </div>
              {encounter.subscriber_primary?.insurance_card?.payer_name}
            </div>
          </ListItem>
        ))}
      </ul>
      <AGeneralTablePaginationFooter
        offset={page * encountersPerPage}
        pageSize={searching ? searchLimit : encountersPerPage}
        total={searching ? encounters.length : count}
        onNextClick={() => {
          const lastEncounterOnPage = encounters[encounters.length - 1]

          if (lastEncounterOnPage) {
            setCursors(prevCursors => prevCursors.concat(lastEncounterOnPage?.oid))
            setPage(++page)
            setIndex(0)
          }
        }}
        onPreviousClick={() => {
          setCursors(prevCursors => prevCursors.slice(0, -1))
          setPage(--page)
          setIndex(0)
        }}
      />
    </>
  )
}

export default EncounterList
