/* eslint-disable no-magic-numbers */

import { useViewportSize } from '@mantine/hooks'
import {
  BannerPortal,
  Box,
  CalendarIcon,
  Flex,
  Group,
  ScrollArea,
  Stack,
  Text,
  TitleTwo,
} from '@shared/components'
import {
  AcuityBlock,
  Appointment,
  AppointmentEventForRender,
  AppointmentTypeString,
  AppointmentWithNoteDetails,
  BlockEventForRender,
  CalendarColumnData,
  CalendarColumnHeader,
  CalendarEventForRender,
  HourRange,
  OOOEventForRender,
  SlotEvent,
  SlotEventForRender,
  UnusedTimeEventForRender,
} from '@shared/types'
import { IANAZone, dayjs, sortBy, toTime } from '@shared/utils'
import sum from 'lodash/sum'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ALargeLoadingSpinner from '../../components/atoms/ALargeLoadingSpinner'
import { useFlags } from '../../utils/hooks'
import { convertRemToPixels } from '../../utils/pixels'
import { AddCalendarEvent } from './AddCalendarEvent'
import { ColumnData } from './ColumnData'
import { ColumnHeader } from './ColumnHeader'
import { ScheduleVisit } from './ScheduleVisit'
import CalendarHeader from './header/CalendarHeader'

export type OVisitsProps = {
  columns: CalendarColumnHeader[]
  data: CalendarColumnData[]
  date: string
  setDate: (date: string) => void
  calendarId?: string
  setCalendarId: (calendarId: string) => void
  isLoading?: boolean

  // Rendered in a drawer, which leads to some custom styling
  drawerStyling?: boolean
  rescheduleAppointment?: Appointment | undefined
  onCloseDrawer?: () => void
  includeOutOfOffice?: boolean
  visitTypesAllowed?: AppointmentTypeString[] | null
}

// The height of an hour-long block in rem, used to calculate everything else
export const HOUR_HEIGHT = 8.0

// The width of the left and right sidebars demarcating the hour labels
const TIME_LABEL_WIDTH = '52px'

// The amount to pull the time labels up. This is to bring them inline with the hour lines
const TIME_LABEL_OFFSET = '-0.5rem'

// Calculate how far down the screen to draw based on minutes since start of day, 60 minutes / HOUR_HEIGHT
const MIN_TO_REM_DIVIDER = 60 / HOUR_HEIGHT
export const minToTopRem = (min: number) => min / MIN_TO_REM_DIVIDER

// How many minutes into the day to start the render? Ex 300 = 5am
const MINUTES_OFFSET = 300

// The maximum number of overlapping events before they reset back to non-overlapping
const MAX_INDENT = 4

// The number of hours to render on the screen
export const HOURS_SHOWN = 24 - MINUTES_OFFSET / 60

const calculateMinutesSinceStartOfDay = (d: dayjs.Dayjs) => {
  return d.diff(dayjs(d).startOf('day'), 'minutes') - MINUTES_OFFSET
}

const calculateBarLocation = () => {
  const d = dayjs()
  const minutesSinceStartOfDayToday =
    d.get('hours') * toTime('1 hour').min() + d.get('minutes') - MINUTES_OFFSET
  return minToTopRem(minutesSinceStartOfDayToday)
}

const calculateIndentation = (data: CalendarEventForRender[]): CalendarEventForRender[] => {
  // Sort the blocks so that for overlapping appointments the later start will draw on top
  return data
    .sort((a, b) => {
      // Draw OOO blocks on the bottom
      if (a.type === 'ooo') {
        return -1
      }
      if (b.type === 'ooo') {
        return 1
      }

      if (a.start === b.start && a.type === 'appointment' && b.type === 'appointment') {
        return a.event.canceled ? -1 : 1
      }

      if (dayjs(a.start).diff(dayjs(b.start)) < 0) {
        return -1
      }

      return 1
    })
    .map((block, i, sortedData) => {
      const currentBlock = block

      // Won't overlap anything, given they're by definition empty parts of the calendar
      if (currentBlock.type === 'slot' || currentBlock.type === 'ooo') {
        return currentBlock
      }

      if (i > 0) {
        const previousBlock = sortedData[i - 1]

        if (!previousBlock) {
          return currentBlock
        }

        const lastVisitStartMinutes = calculateMinutesSinceStartOfDay(dayjs(previousBlock.start))
        const lastVisitEndMinutes = lastVisitStartMinutes + previousBlock.duration

        const currentVisitStartMinutes = calculateMinutesSinceStartOfDay(dayjs(currentBlock.start))

        // Is the start of the current before the end of the last?
        if (currentVisitStartMinutes < lastVisitEndMinutes) {
          if ((data[i - 1]?.indent || 0) > MAX_INDENT) {
            data[i]!.indent = 0
          } else {
            // If so, we indent 1 deeper than previous
            data[i]!.indent = data[i - 1]!.indent + 1
          }
        }
      }

      return currentBlock
    })
}

const calculateFirstEvent = (data: CalendarEventForRender[]): number => {
  if (data.filter(event => event.top > 0).length === 0) {
    // Doesn't matter, as the empty state will show
    return 0
  }
  return data.filter(event => event.top > 0).sort(sortBy({ key: 'top', order: 'ASC' }))[0]?.top ?? 0
}

type CalDay = {
  id: string
  sameDay: boolean
  blocks: CalendarEventForRender[]
  workingHours: HourRange[] | undefined
}

const CALENDAR_HEADER_HEIGHT = 60

const OVisits = ({
  columns,
  data,
  date,
  setDate,
  calendarId,
  setCalendarId,
  isLoading,
  drawerStyling = false,
  rescheduleAppointment,
  onCloseDrawer = () => ({}),
  includeOutOfOffice = false,
  visitTypesAllowed,
}: OVisitsProps) => {
  const [calEvents, setCalEvents] = useState<CalDay[]>([])
  const [scrollToEvent, setScrollToEvent] = useState<number>()
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [bar, setBar] = useState<number>(calculateBarLocation())
  const { unusedTime } = useFlags()
  const viewportRef = useRef<HTMLDivElement>(null)
  const [manualShowSidebar, setManualShowSidebar] = useState(false)
  const [possibleSlots, setPossibleSlots] = useState<SlotEvent[]>([])
  const [customSlot, setCustomSlot] = useState<SlotEvent[]>([])

  const [selectedPatient, setSelectedPatient] = useState<string | undefined>()

  useEffect(() => {
    const interval = setInterval(() => {
      setBar(calculateBarLocation())
    }, toTime('1 min').ms())
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const eventsByDay: CalDay[] = data.map(day => {
      let dayBlocks: CalendarEventForRender[] = []

      dayBlocks.push(
        ...day.visits.map(
          (visit: AppointmentWithNoteDetails): AppointmentEventForRender => ({
            type: 'appointment',
            event: visit,
            top: minToTopRem(calculateMinutesSinceStartOfDay(dayjs(visit.datetime))),
            indent: 0,
            start: visit.datetime,
            duration: Number(visit.duration),
          }),
        ),
      )

      dayBlocks.push(
        ...day.blocks.map(
          (block: AcuityBlock): BlockEventForRender => ({
            type: 'block',
            event: block,
            top: minToTopRem(calculateMinutesSinceStartOfDay(dayjs(block.start))),
            indent: 0,
            start: dayjs(block.start).toISOString(),
            duration: dayjs(block.end).diff(dayjs(block.start), 'minute'),
          }),
        ),
      )

      dayBlocks.push(
        ...[...possibleSlots, ...customSlot]
          .filter(slot => dayjs(slot.time).isSame(day.date, 'day'))
          .map(
            (slot: SlotEvent): SlotEventForRender => ({
              type: 'slot',
              event: slot,
              top: minToTopRem(calculateMinutesSinceStartOfDay(dayjs(slot.time))),
              indent: 0,
              start: slot.time,
              duration: slot.duration,
            }),
          ),
      )

      // Only show Unused time blocks for past dates with calendar events
      if (dayBlocks.length && unusedTime) {
        dayBlocks.sort(sortBy({ key: 'start', order: 'ASC' })).forEach((dayBlock, index) => {
          const nextBlock = dayBlocks[index + 1]
          /*
           * Only show Unused time block if there is an upcoming time block that day.
           * We should update this after we implement working hours.
           * For now, this prevents us from showing any Unused time blocks at the
           * beginning of the day before the first calendar event, and at the end of
           * the day after the last calendar event.
           */
          if (nextBlock?.start && dayjs(nextBlock.start).isBefore(dayjs())) {
            const endOfCurrentBlock = dayjs(dayBlock.start)
              .add(dayBlock.duration, 'minutes')
              .toISOString()
            const duration = dayjs(nextBlock.start).diff(endOfCurrentBlock, 'minutes')
            if (duration > 0) {
              const unusedBlock: UnusedTimeEventForRender = {
                type: 'unused',
                duration,
                start: endOfCurrentBlock,
                indent: 0,
                top: minToTopRem(calculateMinutesSinceStartOfDay(dayjs(endOfCurrentBlock))),
              }
              dayBlocks.push(unusedBlock)
            }
          }
        })
      }

      // Only show working hours on clinician's calendar
      if (calendarId) {
        if (!day.workingHours || day.workingHours.length === 0) {
          const startOfDay = dayjs(day.date).startOf('day')
          const allDayBlock: OOOEventForRender = {
            type: 'ooo',
            top: 0,
            indent: 0,
            start: startOfDay.toISOString(),
            duration: HOURS_SHOWN * 60,
          }
          dayBlocks.push(allDayBlock)
        } else {
          /**
           * To render OOO blocks, we really need to calculate the inverse of working hour blocks - taking the
           * end of one block and connecting that to the start of the next one. But, the first and last ones
           * need something to connect to (the start and end of the day) so we add them here.
           */
          const ranges = [
            { start: '', end: '00:00' },
            ...day.workingHours,
            { start: '23:59', end: '' },
          ]

          ranges.forEach((range, i, ranges) => {
            if (i === ranges.length - 1) {
              return
            }

            const startOfBlockRange = range.end
            const [startOfBlockHours, startOfBlockMinutes] = startOfBlockRange
              .split(':')
              .map(Number) as [number, number]
            const endOfBlockRange = ranges[i + 1]!.start
            const [endOfBlockHours, endOfBlockMinutes] = endOfBlockRange.split(':').map(Number) as [
              number,
              number,
            ]

            /**
             * In this section we interpret the blocks in ET, which they are stored in, and convert
             * to the local timezone. We skip this for the first and last which are 00:00 and 23:59.
             */
            const startOfBlock = dayjs(day.date)
              .tz(i > 0 ? IANAZone.Eastern : dayjs.tz.guess())
              .set('hours', startOfBlockHours)
              .set('minutes', startOfBlockMinutes)
              .tz(dayjs.tz.guess())
            const endOfBlock = dayjs(day.date)
              .tz(i < ranges.length - 2 ? IANAZone.Eastern : dayjs.tz.guess())
              .set('hours', endOfBlockHours)
              .set('minutes', endOfBlockMinutes)
              .tz(dayjs.tz.guess())

            const block: OOOEventForRender = {
              type: 'ooo',
              top: minToTopRem(calculateMinutesSinceStartOfDay(startOfBlock)),
              indent: 0,
              start: startOfBlock.toISOString(),
              duration: endOfBlock.diff(startOfBlock, 'minutes'),
            }
            dayBlocks.push(block)
          })
        }
      }

      dayBlocks = calculateIndentation(dayBlocks)

      return {
        id: day.id,
        sameDay: dayjs().isSame(day.date, 'day'),
        blocks: dayBlocks,
        workingHours: day.workingHours,
      }
    })

    setCalEvents(eventsByDay)

    const allEventsOnCal = [...eventsByDay.map(day => day.blocks)].flat()
    const firstEvent = calculateFirstEvent(allEventsOnCal)

    setScrollToEvent(firstEvent)
  }, [data, possibleSlots, customSlot, unusedTime, calendarId])

  useEffect(() => {
    setShouldScroll(true)
  }, [date, calendarId, scrollToEvent])

  useLayoutEffect(() => {
    if (shouldScroll && viewportRef.current && scrollToEvent) {
      viewportRef.current.scrollTo(0, convertRemToPixels(scrollToEvent - 2))
      setShouldScroll(false)
    }
  }, [shouldScroll, scrollToEvent])

  const visitCount = sum(
    calEvents.map(day => day.blocks.filter(block => block.type === 'appointment').length),
  )
  const calEventsCount = sum(calEvents.map(day => day.blocks.length))

  const offset = MINUTES_OFFSET / 60
  const totalHours = 24 - MINUTES_OFFSET / 60
  const times: { hour: string }[] = Array.from({ length: totalHours }).map((_, i) => {
    const j = i + offset
    const hour = j > 12 ? j - 12 : j
    const period = j >= (totalHours + offset) / 2 ? 'pm' : 'am'
    return {
      hour: `${hour}${period}`,
    }
  })

  const canShowSidebar = Boolean(calendarId)
  const shouldShowSidebar = manualShowSidebar || drawerStyling
  const showSidebar = canShowSidebar && shouldShowSidebar
  const { height, width } = useViewportSize()

  return (
    <Box
      sx={{
        height: height - CALENDAR_HEADER_HEIGHT,
        maxWidth: drawerStyling ? `${0.8 * width}px` : undefined,
      }}
    >
      <BannerPortal />
      <Flex
        sx={{ overflowX: 'auto', overflowY: 'hidden', maxHeight: height - CALENDAR_HEADER_HEIGHT }}
      >
        <Flex sx={{ flex: 3, minWidth: drawerStyling ? `${0.2 * width}px` : '32rem' }} mx='md'>
          <Stack w='100%'>
            <CalendarHeader
              drawerStyling={drawerStyling}
              date={date}
              setDate={setDate}
              calendarId={calendarId}
              setCalendarId={setCalendarId}
              visitCount={visitCount}
              setShouldShowSidebar={setManualShowSidebar}
            />
            {isLoading ? (
              <ALargeLoadingSpinner />
            ) : (
              <ScrollArea type='always' viewportRef={viewportRef}>
                {columns.length > 0 && calEventsCount > 0 && (
                  <Group
                    py='xs'
                    spacing={0}
                    noWrap
                    test-id='content:calendar#loaded'
                    sx={({ other: { colors } }) => ({
                      position: 'sticky',
                      top: 0,
                      backgroundColor: colors.background[0],
                      // Setting zIndex to 2 so it lays over the selected time slots which have a zIndex of 1
                      zIndex: 2,
                    })}
                  >
                    <Box sx={{ width: TIME_LABEL_WIDTH }} />
                    {columns.map(item => (
                      <ColumnHeader
                        key={item.id}
                        column={item}
                        totalColumns={columns.length}
                        highlightDay={Boolean(calendarId) && dayjs().isSame(item.date, 'day')}
                      />
                    ))}
                    <Box sx={{ width: TIME_LABEL_WIDTH }} />
                  </Group>
                )}
                {calEventsCount > 0 ? (
                  <Group spacing={0} noWrap align='normal'>
                    <Box key='time-col-data' mt={TIME_LABEL_OFFSET}>
                      {times.map(time => (
                        <Text w='max-content' key={time.hour} h={`${HOUR_HEIGHT}rem`} mr='md'>
                          {time.hour}
                        </Text>
                      ))}
                    </Box>
                    {calEvents.map(day => (
                      <ColumnData
                        hasAvailableSlots={possibleSlots.length > 0}
                        patientId={selectedPatient}
                        key={day.id}
                        blocks={day.blocks}
                        weekView={calEvents.length === 7}
                        bar={day.sameDay ? bar : undefined}
                      />
                    ))}
                    <Box key='time-col-data-end' mt={TIME_LABEL_OFFSET}>
                      {times.map(time => (
                        <Text w='max-content' key={time.hour} h={`${HOUR_HEIGHT}rem`} ml='md'>
                          {time.hour}
                        </Text>
                      ))}
                    </Box>
                  </Group>
                ) : (
                  <Stack align='center' test-id='content:calendar#empty' pt='xl'>
                    <CalendarIcon size='xl' />
                    <TitleTwo>No visits</TitleTwo>
                  </Stack>
                )}
              </ScrollArea>
            )}
          </Stack>
        </Flex>
        {showSidebar && calendarId && (
          <Flex
            sx={{
              flex: 1,
              minWidth: '16rem',
              maxWidth: '28rem',
              maxHeight: height - CALENDAR_HEADER_HEIGHT,
              borderLeftWidth: '1px',
            }}
            py='md'
          >
            {includeOutOfOffice ? (
              <AddCalendarEvent
                setSelectedPatient={setSelectedPatient}
                date={date}
                setCustomSlot={setCustomSlot}
                setDate={setDate}
                calendarId={calendarId}
                setCalendarId={setCalendarId}
                setSlots={setPossibleSlots}
                showTitle={!drawerStyling}
                onCloseDrawer={() => {
                  setManualShowSidebar(false)
                  setPossibleSlots([])
                  setCustomSlot([])
                  setSelectedPatient(undefined)
                  onCloseDrawer()
                }}
                rescheduleAppointment={rescheduleAppointment}
              />
            ) : (
              <ScheduleVisit
                setSelectedPatient={setSelectedPatient}
                date={date}
                setCustomSlot={setCustomSlot}
                setDate={setDate}
                calendarId={calendarId}
                setCalendarId={setCalendarId}
                setSlots={setPossibleSlots}
                showTitle={!drawerStyling}
                onCloseDrawer={() => {
                  setManualShowSidebar(false)
                  setPossibleSlots([])
                  setCustomSlot([])
                  setSelectedPatient(undefined)
                  onCloseDrawer()
                }}
                rescheduleAppointment={rescheduleAppointment}
                visitTypesAllowed={visitTypesAllowed}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default OVisits
